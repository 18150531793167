import axios from 'axios'

const domain = ""

let baseURL = 'https://pnuemathealth.com/api/v1/admin/'

if (process.env.NODE_ENV === "development") {
  baseURL = 'http://localhost:8000/api/v1/admin/'
}

export default axios.create({
  domain,
  baseURL: baseURL,
  credentials: 'include'
  // You can add your headers here
})
