<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">Bulk Certificate Upload List</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'import-list' }">Bulk Certificates Upload List</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Imports</h4>
            <b-table
              :items="imports"
              id="table-list"
              :busy="isBusy"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
            >
              <template v-slot:cell(status)="data">
                {{
                  data.item.status_readable
                }}
              </template>

              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
    <div></div>
    <vue-snotify></vue-snotify>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
  },
  data: function () {
    return {
      sortBy: "id",
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      perPage: 0,
      currentPage: 1,
      isBusy: true,
      sortable: true,
      filtered: false,
      q: "",
      course_id: "",
      status: 0,
      filterPerPage: 100,
      date: "",
      start_date: "",
      end_date: "",
      statuses: [
        {
          value: 0,
          text: "Pending",
        },
        {
          value: 1,
          text: "Processing",
        },
        {
          value: 2,
          text: "Completed",
        },
      ],
      fields: [
        { key: "id", sortable: true },
        { key: "name", sortable: true },
        { key: "url", sortable: true },
        { key: "status", sortable: true},
        { key: "created_at", sortable: true }
      ],
    };
  },
  computed: {
    rows() {
      return this.$store.state.student.meta.total;
    },
    paginationPageSize() {
      return this.$store.state.student.meta.per_page;
    },
    imports() {
      return this.$store.state.course.imports
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  watch: {
    currentPage() {
      if (this.currentPage != this.$store.state.student.meta.current_page)
        this.listImports();
    }
  },
  methods: {
    listImports() {
      this.isBusy = true;
      const payload = {
        meta: {
          page: this.currentPage,
          perPage: this.paginationPageSize
        },
      };

      this.$store
        .dispatch("course/fetchImports", payload)
        .then(() => {
          this.isBusy = false;
          this.filtered = false;
        })
        .catch(() => {
          this.isBusy = false;
        });
    },
  },
  created() {
    this.listImports();
  },
};
</script>
