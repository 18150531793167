import moment from 'moment'

export default {
  formatTime(time, format) {
    return moment(time).format(format)
  },
  formatUnix(unix, format) {
    return moment.unix(unix).format(format)
  },
  badgeColor(value) {
    if (value == 1)
        return "success"
    return "danger"
  },
  formatNGN(number) {
    return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN', maximumSignificantDigits: 3 }).format(number)
  },
  formatUSD(number) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 3 }).format(number)
  },
  formatNumber(number) {
    return new Intl.NumberFormat('en-NG', {maximumSignificantDigits: 3 }).format(number)
  },
  reduce(word, limit=25) {
    if(word.length <= limit)
      return word
    return word.substring(0,limit) + "..."
  },
  throwValidation(errors, toast) {
    Object.keys(errors).forEach(key => {
        errors[key].forEach(msg => {
          toast.warning(msg, {
            timeout: 2000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true
          })
        })
    })
  }
}
