<template>
  <b-tab>
    <template slot="title">
      <i class="mdi mdi-account-network"></i>Next of kin
    </template>
    <div class="row">
      <div class="col-md-6">
        <p>
          <span class="d-block"><strong>Phone:</strong> {{ kin.phone }}</span>
          <span class="d-block">
            <strong>Email:</strong>
            {{ kin.email }}</span
          >
          <span class="d-block">
            <strong>Relationship:</strong>
            {{ kin.relationship }}</span
          >
          <span class="d-block">
            <strong>Title:</strong>
            {{ kin.title }}</span
          >
        </p>
      </div>
      <div class="col-md-6">
        <p>
          <span class="d-block"
            ><strong>First Name:</strong> {{ kin.first_name }}</span
          >
          <span class="d-block">
            <strong>Last Name</strong>
            {{ kin.last_name }}</span
          >
          <span class="d-block">
            <strong>Middle Name</strong>
            {{ kin.middle_name }}</span
          >
        </p>
      </div>
    </div>
  </b-tab>
</template>
<script>
export default {
  props: {
    kin: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
