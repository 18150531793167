var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"forms"},[_c('div',{staticClass:"page-header"},[_c('h3',{staticClass:"page-title"},[_vm._v("Update Student")]),_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'student-list' }}},[_vm._v("Students")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'view-student', params: { id: _vm.$route.params.id } }}},[_vm._v(_vm._s(_vm.first_name)+" "+_vm._s(_vm.last_name))])],1),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v("Update")])])])]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"forms-sample",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 grid-margin stretch-card"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title"},[_vm._v("Student Data")]),_c('p',{staticClass:"card-description"},[_vm._v("Please input patient data here.")]),_c('ValidationProvider',{attrs:{"name":"first_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"First Name","label-for":"first_name"}},[_c('b-form-input',{attrs:{"type":"text","id":"first_name","placeholder":"First Name"},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),_c('p',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"last_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Last Name","label-for":"last_name"}},[_c('b-form-input',{attrs:{"type":"text","id":"last_name","placeholder":"Last Name"},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}}),_c('p',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"middle_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Middle Name","label-for":"middle_name"}},[_c('b-form-input',{attrs:{"type":"text","id":"middle_name","placeholder":"Middle Name"},model:{value:(_vm.middle_name),callback:function ($$v) {_vm.middle_name=$$v},expression:"middle_name"}}),_c('p',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"age","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"DoB","label-for":"dob"}},[_c('date-picker',{staticStyle:{"width":"100%"},attrs:{"format":"YYYY-MM-DD"},model:{value:(_vm.date_of_birth),callback:function ($$v) {_vm.date_of_birth=$$v},expression:"date_of_birth"}}),_c('p',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"sex","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label-for":"sex","horizontal":"","label":"Gender"}},[_c('b-form-select',{attrs:{"id":"sex","options":_vm.sexes},model:{value:(_vm.sex),callback:function ($$v) {_vm.sex=$$v},expression:"sex"}}),_c('p',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Email address","label-for":"email"}},[_c('b-form-input',{attrs:{"type":"email","id":"email","placeholder":"Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('p',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('b-form-input',{attrs:{"type":"text","id":"phone","placeholder":"Phone"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('p',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Address","label-for":"address"}},[_c('b-form-textarea',{attrs:{"id":"address","placeholder":"Address","rows":"3","max-rows":"6"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('p',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1)])]),_c('div',{staticClass:"col-md-6 grid-margin stretch-card"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title"},[_vm._v("Next of Kin Data")]),_c('p',{staticClass:"card-description"},[_vm._v("Please input next of kin data here.")]),_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Title","label-for":"title"}},[_c('b-form-input',{attrs:{"type":"text","id":"title","placeholder":"Title"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('p',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"relationship","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Relationship","label-for":"relationship"}},[_c('b-form-input',{attrs:{"type":"text","id":"relationship","placeholder":"Relationship"},model:{value:(_vm.relationship),callback:function ($$v) {_vm.relationship=$$v},expression:"relationship"}}),_c('p',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"kin_first_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"First Name","label-for":"kin_first_name"}},[_c('b-form-input',{attrs:{"type":"text","id":"first_name","placeholder":"First Name"},model:{value:(_vm.kin_first_name),callback:function ($$v) {_vm.kin_first_name=$$v},expression:"kin_first_name"}}),_c('p',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"kin_last_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Last Name","label-for":"kin_last_name"}},[_c('b-form-input',{attrs:{"type":"text","id":"kin_last_name","placeholder":"Last Name"},model:{value:(_vm.kin_last_name),callback:function ($$v) {_vm.kin_last_name=$$v},expression:"kin_last_name"}}),_c('p',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"kin_middle_name","rules":""}},[_c('b-form-group',{attrs:{"label":"Middle Name","label-for":"kin_middle_name"}},[_c('b-form-input',{attrs:{"type":"text","id":"kin_middle_name","placeholder":"Middle Name"},model:{value:(_vm.kin_middle_name),callback:function ($$v) {_vm.kin_middle_name=$$v},expression:"kin_middle_name"}})],1)],1),_c('ValidationProvider',{attrs:{"name":"kin_email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Email address","label-for":"email"}},[_c('b-form-input',{attrs:{"type":"email","id":"email","placeholder":"Email"},model:{value:(_vm.kin_email),callback:function ($$v) {_vm.kin_email=$$v},expression:"kin_email"}}),_c('p',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"kin_phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('b-form-input',{attrs:{"type":"text","id":"phone","placeholder":"Phone"},model:{value:(_vm.kin_phone),callback:function ($$v) {_vm.kin_phone=$$v},expression:"kin_phone"}}),_c('p',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1)])]),_c('div',{staticClass:"col-12 grid-margin stretch-card"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-form-group',{staticClass:"float-right"},[_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"success","disabled":invalid}},[_vm._v(_vm._s(_vm.create_text))])],1)],1)])])])])]}}])}),_c('vue-snotify')],1)}
var staticRenderFns = []

export { render, staticRenderFns }