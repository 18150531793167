import axios from "../../axios/index.js"

export default {
  fetchStudents(meta) {
    return axios.get("/user/students", {
      params: meta
    })
  },
  fetchPendingStudents(meta) {
    return axios.get("/user/pending-students", {
      params: meta
    })
  },
  filterStudentsByCourses(meta) {
    return axios.get("/courses/students/filter", {
      params: meta
    })
  },
  fetchCodes(meta) {
    return axios.get("/user/codes", {
      params: meta
    })
  },
  fetchCertificates(meta) {
    return axios.get("courses/certificates", {
      params: meta
    })
  },
  filterCertificates(meta) {
    return axios.get("courses/certificates/filter", {
      params: meta
    })
  },
  fetchStudent(id) {
    return axios.get(`user/students/${id}`)
  },Result(pin) {
    return axios.patch(`manage/test/send/${pin}`)
  },
  approveApplication(payload) {
    return axios.patch("user/students/approve", payload)
  },
  deleteStudent(payload) {
    return axios.post("user/students/delete", payload)
  },
  createStudent(payload) {
    return axios.post("user/students", payload)
  },
  updateStudent(payload) {
    return axios.patch(`user/students/${payload.id}`, payload)
  },
}
