export default {
  isUserLoggedIn: () => {
      let user = JSON.parse(localStorage.getItem('userInfo'))
      if(user) {
        let time_used = ((new Date().getTime() - user.timestamp))
        if((time_used < (2 * 60 * 60 * 1000)) || user.remember_me) {
          return true
        }
        else {
          localStorage.removeItem("userInfo")
        }
      }
      return false
  },
}
