import Vue from "vue"
import { AclInstaller, AclCreate, AclRule } from "vue-acl"
import router from "@/router"

Vue.use(AclInstaller)

let initialRole = "public"

let userInfo = JSON.parse(localStorage.getItem("userInfo"))
if(userInfo && userInfo.userRole) initialRole = userInfo.userRole

export default new AclCreate({
  initial: initialRole,
  notfound: "/error-pages/error-404",
  router,
  acceptLocalRules: true,
  globalRules: {
    super_admin: new AclRule("super_admin").generate(),
    basic_admin: new AclRule("basic_admin").or("admin").generate(),
    patient_manager: new AclRule('patient_manager').or('admin').generate(),
    admin: new AclRule("admin").or("super_admin").generate(),
    editor: new AclRule("editor").or("super_admin").or("basic_admin").or("admin").generate(),
    public: new AclRule("public").or("admin").or("editor").generate(),
  }
})
