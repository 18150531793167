<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">Students</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'student-list' }">Students</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <form action="#">
          <div class="form-group d-lg-flex">
            <input
              type="text"
              class="form-control"
              placeholder="Search Here"
              v-model="q"
            />
            <button
              type="button"
              class="btn btn-primary ml-0 ml-lg-3 mt-2 mt-lg-0"
              @click="fetchStudents"
              :disabled="q == ''"
            >
              Search
            </button>
            <button
              type="button"
              class="btn btn-primary ml-0 ml-lg-3 mt-2 mt-lg-0"
              @click="exportCsv('csv')"
            >
              Export
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Filter Students By Courses</h4>
            <div class="row">
              <div class="col-md-4">
                <b-form-group label-for="course" horizontal label="Course">
                  <b-form-select
                    id="course"
                    v-model="course_id"
                    :options="courses"
                  />
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label-for="status" horizontal label="Status">
                  <b-form-select
                    id="status"
                    v-model="status"
                    :options="statuses"
                  />
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label="perPage" label-for="perPage">
                  <b-form-input
                    type="number"
                    id="perPage"
                    v-model="filterPerPage"
                    placeholder="perPage"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-5">
                <date-picker
                  style="width: 100%"
                  v-model="date"
                  range
                  format="YYYY-MM-DD"
                ></date-picker>
              </div>
              <div class="col-md-3">
                <button
                  type="button"
                  class="btn btn-primary ml-0 ml-lg-3 mt-2 mt-lg-0"
                  @click="filterStudents"
                  :disabled="disableFilter"
                >
                  Filter
                </button>
              </div>
              <div class="col-md-4">
                <button
                  type="button"
                  class="btn btn-primary ml-0 ml-lg-3 mt-2 mt-lg-0"
                  @click="fetchStudents"
                  :disabled="!filtered"
                >
                  Clear Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Students</h4>
            <b-table
              :items="students"
              id="table-list"
              :busy="isBusy"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
            >
              <template v-slot:cell(courses)="data">
                {{
                  reduce(data.item.courses
                    .map((c) => {
                      return c.name;
                    })
                    .join(", "))
                }}
              </template>

                            <template v-slot:cell(email)="data">
                {{
                  reduce(data.item.email, 13)
                }}
              </template>

              <template v-slot:cell(action)="data">
                <span>
                  <button
                    v-if="activeUserInfo.userRole == 'admin'"
                    @click="viewPage(data.item.id)"
                    class="btn btn-outline-primary px-4 py-1"
                  >
                    <i class="mdi mdi-eye-outline text-primary mr-2"></i>
                  </button>
                  <button
                    @click="editStudent(data.item.id)"
                    class="btn btn-outline-primary px-4 py-1"
                  >
                    <i class="mdi mdi-account-edit text-primary mr-2"></i>
                  </button>
                </span>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
    <div></div>
    <vue-snotify></vue-snotify>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import moment from "moment";
import exportFromJSON from "export-from-json";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    DatePicker,
  },
  data: function () {
    return {
      sortBy: "id",
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      perPage: 0,
      currentPage: 1,
      isBusy: true,
      sortable: true,
      filtered: false,
      q: "",
      course_id: "",
      status: 0,
      filterPerPage: 100,
      date: "",
      start_date: "",
      end_date: "",
      statuses: [
        {
          value: 0,
          text: "On-going Studies",
        },
        {
          value: 1,
          text: "Certificate In-Transit",
        },
        {
          value: 2,
          text: "Issued",
        },
      ],
      fields: [
        { key: "student_id", sortable: true },
        { key: "first_name", sortable: true },
        { key: "last_name", sortable: true },
        { key: "phone", sortable: true },
        { key: "email", sortable: true },
        { key: "courses", sortable: true },
        { key: "created_at", sortable: true },
        { key: "action", sortable: true },
      ],
    };
  },
  computed: {
    rows() {
      return this.$store.state.student.meta.total;
    },
    paginationPageSize() {
      return this.$store.state.student.meta.per_page;
    },
    students() {
      return this.$store.state.student.students;
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    courses() {
      return this.$store.getters["course/listCoursesWithId"];
    },
    disableFilter() {
      return (
        this.course_id === "" ||
        (this.start_date === "" && this.end_date !== "") ||
        (this.end_date === "" && this.start_date !== "")
      );
    },
  },
  watch: {
    currentPage() {
      if (this.currentPage != this.$store.state.student.meta.current_page)
        this.fetchStudents();
    },
    date() {
      if (this.date != "") {
        this.start_date = moment(this.date[0]).format("YYYY-MM-DD");
        this.end_date = moment(this.date[1]).format("YYYY-MM-DD");
      } else {
        this.start_date = "";
        this.end_date = "";
        this.fetchStudents();
      }
    },
  },
  methods: {
    viewPage(id) {
      this.$router.push("/students/" + id + "/view");
    },
    editStudent(id) {
      this.$router.push("/students/" + id);
    },
    fetchStudents() {
      this.isBusy = true;
      const payload = {
        meta: {
          page: this.currentPage,
          perPage: this.paginationPageSize,
          q: this.q,
        },
      };

      for (const [key, value] of Object.entries(payload.meta)) {
        if (value === "") delete payload.meta[key];
      }

      this.$store
        .dispatch("student/fetchStudents", payload)
        .then(() => {
          this.isBusy = false;
          this.filtered = false;
        })
        .catch(() => {
          this.isBusy = false;
        });
    },
    filterStudents() {
      this.isBusy = true;
      const payload = {
        meta: {
          page: 1,
          perPage: this.filterPerPage,
          course_id: this.course_id,
          status: this.status,
          start_date: this.start_date,
          end_date: this.end_date,
        },
      };

      for (const [key, value] of Object.entries(payload.meta)) {
        if (value === "") delete payload.meta[key];
      }

      this.$store
        .dispatch("student/filterStudentsByCourses", payload)
        .then(() => {
          this.isBusy = false;
          this.filtered = true;
        })
        .catch(() => {
          this.isBusy = false;
        });
    },
    exportCsv(type = "csv") {
      let csv = JSON.parse(JSON.stringify(this.students));

      let data = csv.map((s) => {
        return {
          "Student Id": s.student_id,
          "First Name": s.first_name,
          "Last_Name": s.last_name,
          "Middle Name": s.middle_name,
          "Email": s.email,
          "Phone": s.phone
        };
      });

      let fileName = "export"

      let exportType = type;

      exportFromJSON({ data, fileName, exportType });
    },
    reduce(word, limit=25) {
      return this.$helper.reduce(word, limit)
    }
  },
  created() {
    this.fetchStudents();
    this.$store.dispatch("course/fetchCourses");
  },
};
</script>
