/* eslint-disable no-debugger, no-console */

const mutations = {

    UPDATE_USER_INFO(state, payload) {

      // Get Data localStorage
      let userInfo = JSON.parse(localStorage.getItem("userInfo")) || state.AppActiveUser

      for (const property of Object.keys(payload)) {

        if (payload[property] != null) {
          // If some of user property is null - user default property defined in state.AppActiveUser
          state.AppActiveUser[property] = payload[property]

          // Update key in localStorage
          userInfo[property] = payload[property]
        }

      }

      userInfo["timestamp"] = new Date().getTime()

      //role
      let role = "admin"

      // if (payload.role == "patient-manager")
      //   role = "patient_manager"

      userInfo["userRole"] = role
      // Store data in localStorage
      localStorage.setItem("userInfo", JSON.stringify(userInfo))
    },
    UPDATE_TIMESTAMP(state, timestamp) {
      state.AppActiveUser['timestamp'] = timestamp
      // Store data in localStorage
      let userInfo = JSON.parse(localStorage.getItem("userInfo"))
      userInfo.timestamp = timestamp
      localStorage.setItem("userInfo", JSON.stringify(userInfo))
    }
  }

  export default mutations
