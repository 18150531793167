import Vue from 'vue'
import Router from 'vue-router'

import store from '@/store/store'

import layout from '../layout'


Vue.use(Router)

const router = new Router({
  linkExactActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: layout,
      children: [
        {
          path: "",
          redirect: "/dashboard"
        },
        {
          path: 'dashboard',
          name: 'dashboard',
          component: () => import('@/pages/dashboard'),
          meta: {
            rule: "patient_manager"
          }
        }
      ]
    },
    {
      path: '/students',
      component: layout,
      children: [
        {
          path: 'list',
          name: 'student-list',
          component: () => import('@/pages/students/StudentsList'),
          meta: {
            rule: "patient_manager"
          }
        },
        {
          path: 'list/pending',
          name: 'pending-student',
          component: () => import('@/pages/students/PendingStudents'),
          meta: {
            rule: "patient_manager"
          }
        },
        {
          path: 'list/codes',
          name: 'code-list',
          component: () => import('@/pages/students/Codes'),
          meta: {
            rule: "patient_manager"
          }
        },
        {
          path: 'new',
          name: 'add-student',
          component: () => import('@/pages/students/AddNew'),
          meta: {
            rule: "patient_manager"
          }
        },
        {
          path: 'certificates',
          name: 'certificates',
          component: () => import('@/pages/students/Certificates'),
          meta: {
            rule: "patient_manager"
          }
        },
        {
          path: ':id',
          name: 'update-student',
          component: () => import('@/pages/students/UpdateStudent'),
          meta: {
            rule: "patient_manager"
          }
        },
        {
          path: ':id/view',
          name: 'view-student',
          component: () => import('@/pages/students/StudentView'),
          meta: {
            rule: "patient_manager"
          }
        },
      ]
    },
    // {
    //   path: '/admins',
    //   component: layout,
    //   children: [
    //     {
    //       path: 'list',
    //       name: 'admin-list',
    //       component: () => import('@/pages/admins/AdminsList'),
    //       meta: {
    //         rule: "admin"
    //       }
    //     },
    //     {
    //       path: 'new',
    //       name: 'admin-add',
    //       component: () => import('@/pages/admins/AddNew'),
    //       meta: {
    //         rule: "admin"
    //       }
    //     },
    //     {
    //       path: ':id',
    //       name: 'admin-view',
    //       component: () => import('@/pages/admins/AdminView'),
    //       meta: {
    //         rule: "admin"
    //       }
    //     }
    //   ]
    // },
    {
      path: '/courses',
      component: layout,
      children: [
        {
          path: 'coordinators/new',
          name: 'add-coordinator',
          component: () => import('@/pages/courses/AddNew'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'list',
          name: 'courses-list',
          component: () => import('@/pages/courses/CourseList'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'coordinators',
          name: 'coordinators-list',
          component: () => import('@/pages/courses/CoordinatorList'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'coordinators/:id',
          name: 'update-coordinator',
          component: () => import('@/pages/courses/UpdateCoordinator'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'registrar',
          name: 'update-registrar',
          component: () => import('@/pages/courses/UpdateRegistrar'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'bulk-completion',
          name: 'bulk-completion',
          component: () => import('@/pages/courses/ImportCourses'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'import/list',
          name: 'import-list',
          component: () => import('@/pages/courses/ImportList'),
          meta: {
            rule: "admin"
          }
        },
      ]
    },
    {
      path: '/',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'login',
          name: 'login',
          component: () => import('@/pages/samples/auth-pages/login'),
          meta: {
            rule: "public"
          }
        },
        {
          path: 'verify',
          name: 'verify',
          component: () => import('@/pages/samples/auth-pages/verify'),
          meta: {
            rule: "public"
          }
        },
        // {
        //   path: 'login-2',
        //   name: 'login-2',
        //   component: () => import('@/pages/samples/auth-pages/login-2'),
        //   meta: {
        //     rule: "public"
        //   }
        // },
        // {
        //   path: 'multi-level-login',
        //   name: 'multi-level-login',
        //   component: () => import('@/pages/samples/auth-pages/multi-level-login')
        // },
        // {
        //   path: 'register',
        //   name: 'register',
        //   component: () => import('@/pages/samples/auth-pages/register')
        // },
        // {
        //   path: 'register-2',
        //   name: 'register-2',
        //   component: () => import('@/pages/samples/auth-pages/register-2')
        // },
        // {
        //   path: 'lock-screen',
        //   name: 'lock-screen',
        //   component: () => import('@/pages/samples/auth-pages/lock-screen')
        // },
      ]
    },
    {
      path: '/error-pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'error-404',
          name: 'error-404',
          component: () => import('@/pages/samples/error-pages/error-404')
        },
        {
          path: 'error-500',
          name: 'error-500',
          component: () => import('@/pages/samples/error-pages/error-500')
        }
      ]
    },
    // {
    //   path: '/general-pages',
    //   component: {
    //     render (c) { return c('router-view') }
    //   },
    //   children: [
    //     {
    //       path: 'landing-page',
    //       name: 'landing-page',
    //       component: () => import('@/pages/samples/general-pages/landing-page')
    //     }
    //   ]
    // },
    // {
    //   path: '/general-pages',
    //   component: layout,
    //   children: [
    //     {
    //       path: 'blank-page',
    //       name: 'blank-page',
    //       component: () => import('@/pages/samples/general-pages/blank-page')
    //     },
    //     {
    //       path: 'profile',
    //       name: 'profile',
    //       component: () => import('@/pages/samples/general-pages/profile'),
    //       meta: {
    //         rule: 'admin'
    //       }
    //     },
    //     {
    //       path: 'faq-1',
    //       name: 'faq-1',
    //       component: () => import('@/pages/samples/general-pages/faq-1')
    //     },
    //     {
    //       path: 'faq-2',
    //       name: 'faq-2',
    //       component: () => import('@/pages/samples/general-pages/faq-2')
    //     },
    //     {
    //       path: 'news-grid',
    //       name: 'news-grid',
    //       component: () => import('@/pages/samples/general-pages/news-grid')
    //     },
    //     {
    //       path: 'timeline',
    //       name: 'timeline',
    //       component: () => import('@/pages/samples/general-pages/timeline')
    //     },
    //     {
    //       path: 'search-result',
    //       name: 'search-result',
    //       component: () => import('@/pages/samples/general-pages/search-result'),
    //       meta: {
    //         rule: "admin"
    //       }
    //     },
    //     {
    //       path: 'portfolio',
    //       name: 'portfolio',
    //       component: () => import('@/pages/samples/general-pages/portfolio')
    //     },
    //     {
    //       path: 'user-listing',
    //       name: 'user-listing',
    //       component: () => import('@/pages/samples/general-pages/user-listing')
    //     },
    //   ]
    // },
    // {
    //   path: '/notifications',
    //   component: layout,
    //   children: [
    //     {
    //       path: '',
    //       name: 'notifications',
    //       component: () => import('@/pages/advanced-ui/notifications'),
    //       meta: {
    //         rule: 'public'
    //       }
    //     }
    //   ]
    // },
    // {
    //   path: '/popups',
    //   component: layout,
    //   children: [
    //     {
    //       path: '',
    //       name: 'popups',
    //       component: () => import('@/pages/advanced-ui/popups')
    //     }
    //   ]
    // },
    // {
    //   path: '/forms',
    //   component: layout,
    //   children: [
    //     {
    //       path: 'basic-elements',
    //       name: 'basic-elements',
    //       component: () => import('@/pages/forms/basic-form-elements'),
    //       meta: {
    //         rule: "admin"
    //       }
    //     },
    //     {
    //       path: 'advanced-elements',
    //       name: 'advanced-elements',
    //       component: () => import('@/pages/forms/advanced-form-elements'),
    //       meta: {
    //         rule: "admin"
    //       }
    //     },
    //     {
    //       path: 'wizard',
    //       name: 'wizard',
    //       component: () => import('@/pages/forms/wizard')
    //     },
    //     {
    //       path: 'validation',
    //       name: 'validation',
    //       component: () => import('@/pages/forms/validation'),
    //       meta: {
    //         rule: "admin"
    //       }
    //     }
    //   ]
    // },
    // {
    //   path: '/text-editors',
    //   component: layout,
    //   children: [
    //     {
    //       path: '',
    //       name: 'text-editors',
    //       component: () => import('@/pages/forms/text-editors'),
    //       meta: {
    //         rule: "admin"
    //       }
    //     }
    //   ]
    // },
    // {
    //   path: '/code-editors',
    //   component: layout,
    //   children: [
    //     {
    //       path: '',
    //       name: 'code-editors',
    //       component: () => import('@/pages/forms/code-editors')
    //     }
    //   ]
    // },
    {
      path: '/icons',
      component: layout,
      children: [
    //     {
    //       path: 'flag-icons',
    //       name: 'flag-icons',
    //       component: () => import('@/pages/icons/flag-icons')
    //     },
    //     {
    //       path: 'fontawesome',
    //       name: 'fontawesome',
    //       component: () => import('@/pages/icons/fontawesome')
    //     },
        {
          path: 'mdi-icons',
          name: 'mdi-icons',
          component: () => import('@/pages/icons/mdi-icons'),
          meta: {
            rule: "admin"
          }
        },
    //     {
    //       path: 'simpleline',
    //       name: 'simpleline',
    //       component: () => import('@/pages/icons/simple-line')
    //     },
    //     {
    //       path: 'themify-icons',
    //       name: 'themify-icons',
    //       component: () => import('@/pages/icons/themify')
    //     }
    //   ]
    // },
    // {
    //   path: '/e-commerce',
    //   component: layout,
    //   children: [
    //     {
    //       path: 'invoice',
    //       name: 'invoice',
    //       component: () => import('@/pages/samples/e-commerce/invoice')
    //     },
    //     {
    //       path: 'orders',
    //       name: 'orders',
    //       component: () => import('@/pages/samples/e-commerce/orders')
    //     },
    //     {
    //       path: 'pricing-table',
    //       name: 'pricing-table',
    //       component: () => import('@/pages/samples/e-commerce/pricing-table')
    //     },
    //     {
    //       path: 'product-catalogue',
    //       name: 'product-catalogue',
    //       component: () => import('@/pages/samples/e-commerce/product-catalogue')
    //     },
    //     {
    //       path: 'project-list',
    //       name: 'project-list',
    //       component: () => import('@/pages/samples/e-commerce/project-list')
    //     }
      ]
    },
    // {
    //   path: '/apps',
    //   component: layout,
    //   children: [
    //     {
    //       path: 'calendar',
    //       name: 'calendar',
    //       component: () => import('@/pages/apps/calendar')
    //     },
    //     {
    //       path: 'email',
    //       name: 'email',
    //       component: () => import('@/pages/apps/email')
    //     },
    //     {
    //       path: 'todo',
    //       name: 'todo',
    //       component: () => import('@/pages/apps/todo')
    //     },
    //     {
    //       path: 'gallery',
    //       name: 'gallery',
    //       component: () => import('@/pages/apps/gallery')
    //     },
    //     {
    //       path: 'tickets',
    //       name: 'tickets',
    //       component: () => import('@/pages/apps/tickets')
    //     },
    //     {
    //       path: 'kanban',
    //       name: 'kanban',
    //       component: () => import('@/pages/apps/kanban')
    //     },
    //     {
    //       path: 'chat',
    //       name: 'chat',
    //       component: () => import('@/pages/apps/chat')
    //     },
    //   ]
    // },
    {
      path: '*',
      redirect: '/error-404',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'error-404',
          component: () => import('@/pages/samples/error-pages/error-404')
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  if(to.meta.rule != 'public') {
    if (!store.state.auth.isUserLoggedIn()) next('/login')
  }
   next()
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
