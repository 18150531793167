<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">Courses</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="javascript:void(0);">Courses</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{ list_title }}</h4>
            <b-table
              :items="courses"
              id="table-list"
              responsive
              :busy="isBusy"
              :per-page="perPage"
              :current-page="currentPage"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
            >
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template v-slot:cell(certificate)="data">
                <span
                  ><img class="img-fluid" :src="data.item.certificate"
                /></span>
              </template>

              <template v-slot:cell(action)="data">
                <span>
                  <button
                    v-if="activeUserInfo.userRole == 'admin'"
                    @click="addCoordinator(data.item.id)"
                    class="btn btn-outline-primary px-4 py-1"
                  >
                    <i class="mdi mdi-account-key text-primary mr-2"></i>{{ data.item.coordinator != "N/A" ? "Update" : "Add"}} Coordinator
                  </button>
                </span>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <div></div>
    <vue-snotify></vue-snotify>
    <add-coordinator @added="fetchCourses" :course_id="course_id"></add-coordinator>
  </section>
</template>
<script>
/* eslint-disable no-debugger, no-console */

import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import AddCoordinator from "./components/AddCoordinator.vue"

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  data: function () {
    return {
      q: "",
      sortBy: "id",
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      list_title: "Courses",
      currentPage: 1,
      perPage: 0,
      isBusy: true,
      fields: [
        { key: "id", label: "id", sortable: true },
        { key: "name", label: "name", sortable: true },
        { key: "coordinator", sortable: true },
        { key: "certificate" },
        { key: "created_at", sortable: true },
        { key: "action", sortable: false },
      ],
      course_id: 0
    };
  },
  components: {
    AddCoordinator
  },
  computed: {
    courses() {
      return this.$store.state.course.courses;
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    fetchCourses() {
      this.isBusy = true;

      this.$store
        .dispatch("course/fetchCourses")
        .then(() => {
          this.isBusy = false;
        })
        .catch(() => {
          this.isBusy = false;
        });
    },
    addCoordinator(id) {
      this.course_id = id
      this.$bvModal.show("add_coordinator")
    }
  },
  created() {
    this.fetchCourses();
  },
};
</script>
<style lang="scss" scoped>
.table th img,
.table td img {
  width: 100px;
  height: auto;
  border-radius: 8px;
}
</style>
