import axios from "../../axios/index.js"

export default {
  generateCodes(payload) {
    return axios.post("/user/code", payload)
  },
  addNew(payload) {
    return axios.post(`courses/student/${payload.id}`, payload)
  },
  addCoordinator(payload) {
    return axios.post("courses/coordinators", payload)
  },
  addImport(payload) {
    return axios.post("courses/import/csv", payload)
  },
  updateCoordinator(payload) {
    return axios.post(`courses/coordinators/${payload.id}`, payload.form_data)
  },
  addCoordinatorTo(payload) {
    return axios.patch(`courses/${payload.course_id}/coordinator`, payload)
  },
  removeCourse(id) {
    return axios.delete(`courses/${id}`)
  },
  generateCertificate(id) {
    return axios.patch(`courses/certificate/${id}`)
  },
  sendCertificate(id) {
    return axios.post(`courses/certificate/${id}/send`)
  },
  fetchCourses() {
    return axios.get("courses")
  },
  fetchImports(meta) {
    return axios.get("courses/import/list", {
      params: meta
    })
  },
  fetchStats() {
    return axios.get("courses/stats")
  },
  fetchRegistrar() {
    return axios.get("courses/registrars")
  },
  updateRegistrar(payload) {
    return axios.post("courses/registrars", payload)
  },
  fetchCoordinators() {
    return axios.get("/courses/coordinators")
  },
  fetchCoordinator(id) {
    return axios.get(`/courses/coordinators/${id}`)
  }
}
