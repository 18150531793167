/* eslint-disable no-debugger, no-console */
import jwt from '../../http/requests/auth/jwt/index.js'

export default {
  // JWT
  loginJWT ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt.login(payload.userDetails.email, payload.userDetails.client_type)
        .then(response => {
            commit('SET_EMAIL', {email: payload.userDetails.email, otp_secret: response.data.otp_secret })
            resolve(response)
        })
        .catch(error => {
           reject(error.response.data.message)
         })
    })
  },
  verifyJWT ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt.verify(payload.userDetails.token)
        .then(response => {
          if (response.data) {
            localStorage.setItem('accessToken', response.data.data.token.access_token)
            commit('UPDATE_USER_INFO', response.data.data.user, {root: true})
            location.replace('/')
            resolve(response)
          } else {
            reject({message: 'Wrong Email or Password'})
          }

        })
        .catch(error => {
           reject(error.response.data.message)
         })
    })
  }
}
