const userDefaults = {
  id         : 0,
  first_name : "Admin",
  last_name   : "Admin",
  status      : "online",
  userRole    : "admin",
  email       : "mokhatat@gmail.com"
}

const userInfoLocalStorage = JSON.parse(localStorage.getItem("userInfo")) || {}

const getUserInfo = () => {
  let userInfo = {}

  Object.keys(userDefaults).forEach((key) => {
    // If property is defined in localStorage => Use that
    userInfo[key] = userInfoLocalStorage[key] ?  userInfoLocalStorage[key] : userDefaults[key]
  })

  // Include properties from localStorage
  Object.keys(userInfoLocalStorage).forEach((key) => {
    if(userInfo[key] == undefined && userInfoLocalStorage[key] != null) userInfo[key] = userInfoLocalStorage[key]
  })

  return userInfo
}

const state = {
    AppActiveUser: getUserInfo()
}

export default state
