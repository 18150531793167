<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">
        Codes
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link :to="{name: 'student-list'}">Students</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">Codes</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
          <div class="form-group d-lg-flex">
            <button type="button" class="btn btn-primary ml-0 ml-lg-3 mt-2 mt-lg-0" @click="openCode">Generate Codes</button>
          </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Codes</h4>
            <b-table :items="codes" id="table-list" :busy="isBusy" responsive :per-page="perPage" :current-page="currentPage" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">

              <template v-slot:cell(action)="data">
                <span>
                  <button v-if="activeUserInfo.userRole == 'admin'" @click="viewPage(data.item.id)" class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>Tests</button>
                  <button @click="addTest(data.item.id)" class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>Add Test</button>
                  <button @click="editPatient(data.item.id)" class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-account-edit text-primary mr-2"></i>Edit</button>
                </span>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right">
            </b-pagination>
          </div>
        </div>
      </div>
      </div>
    <div>
    </div>
    <vue-snotify></vue-snotify>
    <generate-code @generated="fetchCodes"></generate-code>
  </section>
</template>
<script>
/* eslint-disable no-debugger, no-console */
import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"
import GenerateCode from  "@/components/GenerateCode"

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  data: function() {
    return {
      sortBy: 'name',
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      perPage: 0,
      currentPage: 1,
      isBusy: true,
      sortable: true,
      q: "",
      fields: [
        { key: 'id', sortable: true},
        { key: 'code', sortable: true },
        { key: 'course', sortable: true},
        { key: 'created_at', sortable: true },
      ],
    };
  },
  components: {
    GenerateCode
  },
  computed: {
    rows() {
      return this.$store.state.student.meta.total
    },
    paginationPageSize() {
      return this.$store.state.student.meta.per_page
    },
    codes() {
      return this.$store.state.student.students
    },
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    },
  },
  watch: {
    q() {
      if (this.q == "")
        this.fetchStudents()
    },
    currentPage() {
      if(this.currentPage != this.$store.state.student.meta.current_page)
        this.fetchCodes()
    }
  },
  methods: {
    openCode() {
      this.$bvModal.show("generate_code")
    },
    viewPage(id) {
      this.$router.push('/patients/'+id+'/tests')
    },
    addTest(id) {
      this.$router.push('/patients/'+id+"/test")
    },
    editPatient(id) {
      this.$router.push('/patients/'+id)
    },
    fetchCodes() {
      this.isBusy = true
      const payload = {
        meta: {
          page: this.currentPage,
          perPage: this.paginationPageSize,
        },
      } 

      this.$store.dispatch("student/fetchCodes", payload)
      .then(() => {
        this.isBusy = false
      })
      .catch(() => { 
        this.isBusy = false
      })
    },
  },
  created() {
    this.fetchCodes()
 },
}
</script>
