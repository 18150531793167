<template>
  <b-modal id="generate_code" title="Generate Code">
    <ValidationObserver v-slot="{ invalid }">
      <form @submit.prevent="onSubmit" class="pt-3">
        <ValidationProvider name="Courses" rules="required">
          <b-form-group
            slot-scope="{ errors }"
            label-for="individual_status"
            horizontal
            label="Courses"
          >
            <b-form-select
              id="courses"
              v-model="course"
              :options="courses"
            />
            <p>{{ errors[0] }}</p>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider name="quantity" rules="required">
          <div slot-scope="{ errors }" class="form-group">
            <input
              type="number"
              v-model="quantity"
              class="form-control form-control-lg"
              id="quantity"
              placeholder="quantity"
            />
            <p>{{ errors[0] }}</p>
          </div>
        </ValidationProvider>
        <div class="mt-3">
          <b-button
            type="submit"
            :disabled="invalid"
            variant="primary"
            class="
              btn btn-block btn-gradient-primary btn-lg
              font-weight-medium
              auth-form-btn
            "
            >{{ processing ? "Processing..." : "Generate Code" }}</b-button
          >
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import { ValidationObserver } from "vee-validate";

export default {
  name: "generate-code",
  components: {
    ValidationObserver,
  },
  data() {
    return {
      quantity: 1,
      course: "",
      processing: false
    };
  },
  computed: {
    courses() {
      return this.$store.getters["course/listCourses"];
    },
  },
  methods: {
    fetchCourses() {
      this.$store.dispatch("course/fetchCourses");
    },
    onSubmit() {
        this.processing = true
        this.$store.dispatch("course/generateCodes", {course: this.course, quantity: this.quantity})
        .then(() => {
            this.$bvModal.hide("generate_code")
            this.$emit("generated")
        })
        .catch(() => {

        })
        .finally(() => {
            this.processing = false
        })
    }
  },
  created() {
    this.fetchCourses();
  },
};
</script>
