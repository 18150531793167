/* eslint-disable no-debugger, no-console */
export default {
    listCourses(state) {
      let courses = state.courses.map(course => {
        return {
          value: course.name,
          text: course.name
        }
      })
      courses.unshift({value: "", text: "Select Course"})
      return courses
    },
    listCoursesWithId(state) {
      let courses = state.courses.map(course => {
        return {
          value: course.id,
          text: course.name
        }
      })
      courses.unshift({value: "", text: "Select Course"})
      return courses
    },
    listCoordinatorsWithId(state) {
      let coordinators = state.coordinators.map(coord => {
        return {
          value: coord.id,
          text: coord.full_name
        }
      })
      coordinators.unshift({value: "", text: "Select Coordinator"})
      return coordinators
    }
 }