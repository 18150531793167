export default {
  SET_STUDENTS(state, students) {
    state.students = students
  },
  SET_STUDENT(state, student) {
    const itemIndex = state.students.findIndex((s) => s.id === student.id)
    if (itemIndex != -1) {
      state.students[itemIndex] = student
    }
    else state.students.push(student)
  },
  SET_CERTIFICATES(state, certificates) {
    state.certificates = certificates
  },
  SET_META(state, meta) {
    state.meta.total = meta.total
    state.meta.to = meta.to
    state.meta.from = meta.from
    state.meta.last_page = meta.last_page
    state.meta.current_page = meta.current_page
    if (meta.current_page != meta.last_page) {
      state.meta.next_page = meta.current_page + 1
    }
  }
}
