import state from './moduleAdminState.js'
import mutations from './moduleAdminMutations.js'
import actions from './moduleAdminActions.js'
import getters from './moduleAdminGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
