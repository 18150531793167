/* eslint-disable no-debugger, no-console */
export default {
  SET_ADMINS(state, admins) {
    state.admins = admins
  },
  SET_OTPS(state, otps) {
    state.otps = otps
  },
  SET_STATS(state, stats) {
    state.stats = stats
  },
  SET_ADMIN(state, admin) {
    if(state.admins.length) {
      const itemIndex = state.admins.findIndex((a) => a.id === admin.id)
      if(itemIndex != -1) {
        state.admins[itemIndex] = admin
      }
      else state.admins.push(admin)
    }
    else state.admins.push(admin)
  },
  SET_META(state, meta) {
   state.meta.total = meta.total
   state.meta.to = meta.to
   state.meta.from = meta.from
   state.meta.last_page = meta.last_page
   state.meta.current_page = meta.current_page
   if(meta.current_page != meta.last_page) {
     state.meta.next_page = meta.current_page + 1
   }
  },
  SET_OTPS_META(state, meta) {
    state.otp_meta.total = meta.total
    state.otp_meta.to = meta.to
    state.otp_meta.from = meta.from
    state.otp_meta.last_page = meta.last_page
    state.meta.current_page = meta.current_page
    if(meta.current_page != meta.last_page) {
      state.otp_meta.next_page = meta.current_page + 1
    }
   },
  UPDATE_PAGINATE(state, size) {
    state.meta.paginate = size
  },
}
