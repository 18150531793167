/* eslint-disable no-debugger, no-console */
export default {
  SET_COURSES(state, courses) {
    state.courses = courses
  },
  SET_IMPORTS(state, imports) {
    state.imports = imports
  },
  SET_COORDINATORS(state, coordinators) {
    state.coordinators = coordinators
  },
  SET_COORDINATOR(state, coordinator) {
    const itemIndex = state.coordinators.findIndex((c) => c.id === coordinator.id)
    if (itemIndex != -1) {
      state.coordinators[itemIndex] = coordinator
    }
    else state.coordinators.push(coordinator)
  },
  SET_COURSE(state, course) {
    const itemIndex = state.courses.findIndex((c) => c.id === course.id)
    if (itemIndex != -1) {
      state.courses[itemIndex] = course
    }
    else state.courses.push(course)
  },
  SET_STATS(state, stats) {
    state.stats = stats
  },
  SET_META(state, meta) {
    state.meta.total = meta.total
    state.meta.to = meta.to
    state.meta.from = meta.from
    state.meta.last_page = meta.last_page
    state.meta.current_page = meta.current_page
    if (meta.current_page != meta.last_page) {
      state.meta.next_page = meta.current_page + 1
    }
  }
}
