<template>
  <b-tab active>
    <template slot="title">
      <i class="mdi mdi-library-books"></i>Courses
    </template>
    <section class="tables">
      <div class="page-header">
        <h3 class="page-title">Courses</h3>
      </div>
      <div class="row">
        <div class="col-12">
          <form action="#">
            <div class="form-group d-lg-flex">
              <button
                type="button"
                class="btn btn-primary ml-0 ml-lg-3 mt-2 mt-lg-0"
                @click="addNew()"
              >
                Add New
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Courses</h4>
              <b-table
                :items="courses"
                id="table-list"
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
              >
                <template v-slot:cell(status)="data">
                  {{ data.item.status_readable }}
                </template>
                <template v-slot:cell(certificate)="data">
                  <span v-if="data.item.certificate !== null"
                    ><a :href="data.item.certificate" target="_blank"><img class="img-fluid" :src="data.item.certificate"
                  /></a></span>
                  <span v-if="data.item.certificate === null"> N/A </span>
                </template>

                <template v-slot:cell(cert_no)="data">
                  {{ data.item.cert_no === null ? "N/A" : data.item.cert_no }}
                </template>

                <template v-slot:cell(action)="data">
                  <span v-if="activeUserInfo.userRole == 'admin'">
                    <button v-if="data.item.status === 2"
                      v-confirm="{
                        loader: true,
                        ok: (dialog) => sendCertificate(dialog, data.item.id),
                        cancel: cancelRemove,
                        message: 'Are you sure want to take this action?',
                      }"
                      class="btn btn-outline-primary px-4 py-1 mr-2"
                    >
                      <i class="mdi mdi-send text-danager mr-2"></i>Send
                    </button>
                    <button
                      v-confirm="{
                        loader: true,
                        ok: (dialog) => generateCertificate(dialog, data.item.id),
                        cancel: cancelRemove,
                        message: 'Are you sure want to take this action?',
                      }"
                      class="btn btn-outline-primary px-4 py-1 mr-2"
                    >
                      <i class="mdi mdi-certificate  text-danager mr-2"></i>Generate
                    </button>
                  </span>
                  <span v-if="activeUserInfo.userRole == 'admin'">
                    <button
                      v-confirm="{
                        loader: true,
                        ok: (dialog) => removeCourse(dialog, data.item.id),
                        cancel: cancelRemove,
                        message: 'Are you sure want to take this action?',
                      }"
                      class="btn btn-outline-danger px-4 py-1 mr-2"
                    >
                      <i class="mdi mdi-message text-danager mr-2"></i>Remove
                    </button>
                  </span>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <vue-snotify></vue-snotify>
    </section>
    <add-new @added="added"></add-new>
  </b-tab>
</template>
<script>
/* eslint-disable no-debugger, no-console */
import AddNew from "./AddNewCourse.vue";
export default {
  props: {
    courses: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    AddNew,
  },
  data() {
    return {
      fields: [
        { key: "id", sortable: true },
        { key: "name", sortable: true },
        { key: "status", sortable: true },
        { key: "cert_no", sortable: true },
        { key: "certificate", sortable: true },
        { key: "start_date", sortable: true},
        { key: "created_at", sortable: true },
        { key: "action", sortable: true },
      ],
      sortBy: "name",
      sortDesc: false,
      perPage: 100,
      currentPage: 1,
    };
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    addNew() {
      this.$bvModal.show("add_course");
    },
    added(data) {
      this.$emit("added", data);
    },
    removeCourse(dialog, id) {
      this.$store
        .dispatch("course/removeCourse", id)
        .then((res) => {
          dialog.close();
          this.$emit("added", res.data.data);
        })
        .catch((err) => {
          dialog.close();
          this.$snotify.warning(err.message, {
            timeout: 2000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
          });
        });
    },
    sendCertificate(dialog, id) {
      this.$store
        .dispatch("course/sendCertificate", id)
        .then(() => {
          dialog.close();
        })
        .catch((err) => {
          dialog.close();
          this.$snotify.warning(err.message, {
            timeout: 2000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
          });
        });
    },
    generateCertificate(dialog, id) {
      this.$store
        .dispatch("course/generateCertificate", id)
        .then((res) => {
          dialog.close();
          this.$emit("added", res.data.data);
        })
        .catch((err) => {
          dialog.close();
          this.$snotify.warning(err.message, {
            timeout: 2000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
          });
        });
    },
    cancelRemove() {},
  },
};
</script>
<style lang="scss" scoped>
.table th img,
.table td img {
  width: 100px;
  height: auto;
  border-radius: 8px;
}
</style>
