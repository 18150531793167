<template>
  <b-modal id="add_course" title="Generate Code">
    <ValidationObserver v-slot="{ invalid }">
      <form @submit.prevent="onSubmit" class="pt-3">
        <ValidationProvider name="Courses" rules="required">
          <b-form-group
            slot-scope="{ errors }"
            label-for="individual_status"
            horizontal
            label="Courses"
          >
            <b-form-select
              id="courses"
              v-model="course"
              :options="courses"
            />
            <p>{{ errors[0] }}</p>
          </b-form-group>
        </ValidationProvider>
        <div class="mt-3">
          <b-button
            type="submit"
            :disabled="invalid"
            variant="primary"
            class="
              btn btn-block btn-gradient-primary btn-lg
              font-weight-medium
              auth-form-btn
            "
            >{{ processing ? "Processing..." : "Add Course" }}</b-button
          >
        </div>
      </form>
    </ValidationObserver>
    <vue-snotify></vue-snotify>
  </b-modal>
</template>
<script>
/* eslint-disable no-debugger, no-console */
import { ValidationObserver } from "vee-validate";

export default {
  name: "generate-code",
  components: {
    ValidationObserver,
  },
  data() {
    return {
      course: "",
      processing: false
    };
  },
  computed: {
    courses() {
      return this.$store.getters["course/listCoursesWithId"];
    },
  },
  methods: {
    fetchCourses() {
      this.$store.dispatch("course/fetchCourses");
    },
    onSubmit() {
        this.processing = true
        this.$store.dispatch("course/addNew", {course_id: this.course, id: this.$route.params.id})
        .then((res) => {
            this.$bvModal.hide("add_course")
            this.$emit("added", res.data.data)
        })
        .catch((err) => {
            this.$snotify.warning(err.message, {
                timeout: 2000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true
            })
        })
        .finally(() => {
            this.processing = false
        })
    }
  },
  created() {
    this.fetchCourses();
  },
};
</script>
