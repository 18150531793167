<template>
  <section class="forms">
    <div class="page-header">
      <h3 class="page-title">Update Student</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'student-list' }">Students</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'view-student', params: { id: $route.params.id } }"
              >{{ first_name }} {{ last_name }}</router-link
            >
          </li>
          <li class="breadcrumb-item active" aria-current="page">Update</li>
        </ol>
      </nav>
    </div>
    <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="form">
      <form @submit.prevent="handleSubmit(onSubmit)" class="forms-sample">
        <div class="row">
          <div class="col-md-6 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Student Data</h4>
                <p class="card-description">Please input patient data here.</p>
                <ValidationProvider name="first_name" rules="required">
                  <b-form-group
                    slot-scope="{ errors }"
                    label="First Name"
                    label-for="first_name"
                  >
                    <b-form-input
                      type="text"
                      id="first_name"
                      v-model="first_name"
                      placeholder="First Name"
                    ></b-form-input>
                    <p>{{ errors[0] }}</p>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="last_name" rules="required">
                  <b-form-group
                    slot-scope="{ errors }"
                    label="Last Name"
                    label-for="last_name"
                  >
                    <b-form-input
                      type="text"
                      id="last_name"
                      v-model="last_name"
                      placeholder="Last Name"
                    ></b-form-input>
                    <p>{{ errors[0] }}</p>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="middle_name">
                  <b-form-group
                    slot-scope="{ errors }"
                    label="Middle Name"
                    label-for="middle_name"
                  >
                    <b-form-input
                      type="text"
                      id="middle_name"
                      v-model="middle_name"
                      placeholder="Middle Name"
                    ></b-form-input>
                    <p>{{ errors[0] }}</p>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="age" rules="required">
                  <b-form-group slot-scope="{ errors }" label="DoB" label-for="dob">
                    <date-picker
                      style="width: 100%"
                      v-model="date_of_birth"
                      format="YYYY-MM-DD"
                    ></date-picker>
                    <p>{{ errors[0] }}</p>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="sex" rules="required">
                  <b-form-group
                    slot-scope="{ errors }"
                    label-for="sex"
                    horizontal
                    label="Gender"
                  >
                    <b-form-select id="sex" v-model="sex" :options="sexes" />
                    <p>{{ errors[0] }}</p>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="email" rules="email">
                  <b-form-group
                    slot-scope="{ errors }"
                    label="Email address"
                    label-for="email"
                  >
                    <b-form-input
                      type="email"
                      id="email"
                      v-model="email"
                      placeholder="Email"
                    ></b-form-input>
                    <p>{{ errors[0] }}</p>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="phone" rules="required">
                  <b-form-group slot-scope="{ errors }" label="Phone" label-for="phone">
                    <b-form-input
                      type="text"
                      id="phone"
                      v-model="phone"
                      placeholder="Phone"
                    ></b-form-input>
                    <p>{{ errors[0] }}</p>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="address" rules="required">
                  <b-form-group
                    label="Address"
                    slot-scope="{ errors }"
                    label-for="address"
                  >
                    <b-form-textarea
                      id="address"
                      v-model="address"
                      placeholder="Address"
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                    <p>{{ errors[0] }}</p>
                  </b-form-group>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <div class="col-md-6 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Next of Kin Data</h4>
                <p class="card-description">Please input next of kin data here.</p>
                <ValidationProvider name="title" rules="required">
                  <b-form-group slot-scope="{ errors }" label="Title" label-for="title">
                    <b-form-input
                      type="text"
                      id="title"
                      v-model="title"
                      placeholder="Title"
                    ></b-form-input>
                    <p>{{ errors[0] }}</p>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="relationship" rules="required">
                  <b-form-group
                    slot-scope="{ errors }"
                    label="Relationship"
                    label-for="relationship"
                  >
                    <b-form-input
                      type="text"
                      id="relationship"
                      v-model="relationship"
                      placeholder="Relationship"
                    ></b-form-input>
                    <p>{{ errors[0] }}</p>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="kin_first_name" rules="required">
                  <b-form-group
                    slot-scope="{ errors }"
                    label="First Name"
                    label-for="kin_first_name"
                  >
                    <b-form-input
                      type="text"
                      id="first_name"
                      v-model="kin_first_name"
                      placeholder="First Name"
                    ></b-form-input>
                    <p>{{ errors[0] }}</p>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="kin_last_name" rules="required">
                  <b-form-group
                    slot-scope="{ errors }"
                    label="Last Name"
                    label-for="kin_last_name"
                  >
                    <b-form-input
                      type="text"
                      id="kin_last_name"
                      v-model="kin_last_name"
                      placeholder="Last Name"
                    ></b-form-input>
                    <p>{{ errors[0] }}</p>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="kin_middle_name" rules="">
                  <b-form-group label="Middle Name" label-for="kin_middle_name">
                    <b-form-input
                      type="text"
                      id="kin_middle_name"
                      v-model="kin_middle_name"
                      placeholder="Middle Name"
                    ></b-form-input>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="kin_email" rules="email">
                  <b-form-group
                    slot-scope="{ errors }"
                    label="Email address"
                    label-for="email"
                  >
                    <b-form-input
                      type="email"
                      id="email"
                      v-model="kin_email"
                      placeholder="Email"
                    ></b-form-input>
                    <p>{{ errors[0] }}</p>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="kin_phone" rules="required">
                  <b-form-group slot-scope="{ errors }" label="Phone" label-for="phone">
                    <b-form-input
                      type="text"
                      id="phone"
                      v-model="kin_phone"
                      placeholder="Phone"
                    ></b-form-input>
                    <p>{{ errors[0] }}</p>
                  </b-form-group>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="col-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <b-form-group class="float-right">
                  <b-button
                    type="submit"
                    variant="success"
                    :disabled="invalid"
                    class="mr-2"
                    >{{ create_text }}</b-button
                  >
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <vue-snotify></vue-snotify>
  </section>
</template>

<script lang="js">
/* eslint-disable no-debugger, no-console */

import { ValidationObserver } from 'vee-validate'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import moment from 'moment'

export default {
  name: 'updatePatient',
  data () {
    return {
      first_name: "",
      last_name: "",
      middle_name: "",
      kin_first_name: "",
      kin_last_name: "",
      kin_middle_name: "",
      date_of_birth: "",
      email: "",
      phone: "",
      kin_email: "",
      kin_phone: "",
      address: "",
      sex: "m",
      title: "",
      relationship: "",
      sexes: [
        {
          value: "male",
          text: "Male"
        },
        {
          value: "female",
          text: "Female"
        }
      ],
      create_text: "Update Student"

    }
  },
  components: {
    ValidationObserver,
    DatePicker
  },
  computed: {
  },
  methods: {
    onSubmit() {
      this.create_text = "Updating..."
      let payload = {
        address: this.address,
        email: this.email,
        phone: this.phone,
        kin_phone: this.kin_phone,
        kin_email: this.kin_email,
        gender: this.sex,
        date_of_birth: moment(this.dob).format("YYYY-MM-DD"),
        first_name: this.first_name,
        last_name: this.last_name,
        middle_name: this.middle_name,
        kin_first_name: this.kin_first_name,
        kin_last_name: this.kin_last_name,
        kin_middle_name: this.kin_middle_name,
        title: this.title,
        relationship: this.relationship,
        id: this.$route.params.id
      }
      this.$store.dispatch('student/updateStudent', payload)
      .then((res) => {
        this.$snotify.success(res.data.message, {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      })
      .catch((err) => {
        if (err?.status_code === 422) {
           this.$helper.throwValidation(err.errors, this.$snotify)
        }
        else {
        this.$snotify.warning("Something went wrong!", {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
        }
      })
      .finally(() => {
        this.create_text = "Update Student"
      })
    },
    fetchStudent() {
      this.$store.dispatch("student/fetchStudent", this.$route.params.id)
      .then((res) => {

        let dob_parts = res.data.data.date_of_birth.split("-")
        this.first_name = res.data.data.first_name
        this.last_name = res.data.data.last_name
        this.middle_name = res.data.data.middle_name
        this.kin_first_name = res.data.data.next_of_kin.first_name
        this.kin_last_name = res.data.data.next_of_kin.last_name
        this.kin_middle_name = res.data.data.next_of_kin.middle_name
        this.title = res.data.data.next_of_kin.title
        this.relationship = res.data.data.next_of_kin.relationship
        this.date_of_birth = new Date(dob_parts[0], dob_parts[1]-1, dob_parts[2])
        this.email = res.data.data.email
        this.phone = res.data.data.phone
        this.kin_email = res.data.data.next_of_kin.email
        this.kin_phone = res.data.data.next_of_kin.phone
        this.address = res.data.data.address
        this.sex = res.data.data.gender
      })
    },
  },
  created() {
    this.fetchStudent()
  }
}
</script>
