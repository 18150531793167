<template>
  <section class="app-sidebar">
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <ul class="nav">
        <li class="nav-item nav-profile">
          <a href="javascript:void(0);" class="nav-link">
            <div class="nav-profile-image">
              <img src="@/assets/images/faces/face1.jpg" alt="profile" />
              <span class="login-status online"></span>
            </div>
            <div class="nav-profile-text d-flex flex-column">
              <span class="font-weight-bold mb-2">{{ name }}</span>
              <span class="text-secondary text-small">{{
                activeUserInfo.userRole
              }}</span>
            </div>
            <i
              class="mdi mdi-bookmark-check text-success nav-profile-badge"
            ></i>
          </a>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/">
            <span class="menu-title">Dashboard</span>
            <i class="mdi mdi-home menu-icon"></i>
          </router-link>
        </li>
        <!-- <li class="nav-item">
          <span class="nav-link" v-b-toggle="'admin-dropdown'">
            <span class="menu-title">Admins</span>
            <i class="menu-arrow"></i>
            <i class=" mdi mdi-account-multiple menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="admin-dropdown">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/admins/list">Admins List</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/admins/new">Add New</router-link>
              </li>
            </ul>
          </b-collapse>
        </li> -->
        <li class="nav-item" v-if="showNav(links.patients.rule)">
          <span class="nav-link" v-b-toggle="'user-dropdown'">
            <span class="menu-title">Students</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-account-multiple menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="user-dropdown">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/students/list"
                  >All Students</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/students/list/pending"
                  >Pending Students</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/students/new"
                  >Add New Student</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/students/list/codes"
                  >Generate Access Code</router-link
                >
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item" v-if="showNav(links.supervisors.rule)">
          <span class="nav-link" v-b-toggle="'plan-dropdown'">
            <span class="menu-title">Courses</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-collage menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="plan-dropdown">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/courses/list"
                  >Courses</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/courses/coordinators"
                  >Coordinators</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/courses/coordinators/new"
                  >Add Coordinator</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/courses/registrar"
                  >Update Registrar</router-link
                >
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item" v-if="showNav(links.supervisors.rule)">
          <span class="nav-link" v-b-toggle="'job-dropdown'">
            <span class="menu-title">Certificates & More</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-certificate menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="job-dropdown">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/students/certificates"
                  >Certificates</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/courses/bulk-completion"
                  >Bulk Certificate upload</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/courses/import/list"
                  >Bulk Certificate upload list</router-link
                >
              </li>
            </ul>
          </b-collapse>
        </li>
      </ul>
    </nav>
  </section>
</template>

<script>
export default {
  name: "sidebar",
  data() {
    return {
      collapses: [{ show: false }, { show: false }, { show: false }],
      links: {
        patients: {
          rule: "admin",
        },
        supervisors: {
          rule: "admin",
        },
      },
    };
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    name() {
      return `${this.activeUserInfo.first_name} ${this.activeUserInfo.last_name}`;
    },
  },
  methods: {
    collapseAll() {
      var exp_elm = document.getElementsByClassName("show");
      if (exp_elm.length > 0) {
        var elm_id = exp_elm[0].id;
        this.$root.$emit("bv::toggle::collapse", elm_id);
      }
    },
    showNav(val) {
      if (
        val == this.activeUserInfo.userRole ||
        this.activeUserInfo.userRole == "admin"
      )
        return true;
      return false;
    },
  },
  mounted() {
    const body = document.querySelector("body");
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll(".sidebar .nav-item").forEach(function (el) {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  },
  watch: {
    $route() {
      document.querySelector("#sidebar").classList.toggle("active");
    },
  },
};
</script>
