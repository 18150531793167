var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"forms"},[_c('div',{staticClass:"page-header"},[_c('h3',{staticClass:"page-title"},[_vm._v("New Coordinator")]),_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'coordinators-list' }}},[_vm._v("Coordinators")])],1),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v("New")])])])]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"forms-sample",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 grid-margin stretch-card"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('ValidationProvider',{attrs:{"name":"first_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"First Name","label-for":"first_name"}},[_c('b-form-input',{attrs:{"type":"text","id":"first_name","placeholder":"First Name"},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),_c('p',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"last_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Last Name","label-for":"last_name"}},[_c('b-form-input',{attrs:{"type":"text","id":"last_name","placeholder":"Last Name"},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}}),_c('p',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1)])]),_c('div',{staticClass:"col-md-6 grid-margin stretch-card"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('ValidationProvider',{attrs:{"name":"signature","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Signature","label-for":"signature"}},[_c('b-form-file',{attrs:{"accept":".png,.jpg,.jpeg,.gif","id":"mobile_banner","state":Boolean(_vm.signature_file),"placeholder":"Choose Signature"},on:{"change":_vm.getMobileURL},model:{value:(_vm.signature_file),callback:function ($$v) {_vm.signature_file=$$v},expression:"signature_file"}}),_c('p',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)}),_c('ul',{staticClass:"text-danger"},[_c('li',[_vm._v("Signature should be in transparent background.")]),_c('li',[_vm._v(" Signature's height must not be less than 400px, and cannot be greater than 450px with width not more than 600px. ")])]),(_vm.signature_url != '')?_c('div',{staticClass:"mt-2"},[_c('img',{attrs:{"src":_vm.signature_url,"width":"150px"}})]):_vm._e()],1)])]),_c('div',{staticClass:"col-12 grid-margin stretch-card"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-form-group',{staticClass:"float-right"},[_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"success","disabled":invalid}},[_vm._v(_vm._s(_vm.create_text))]),_c('b-button',{attrs:{"type":"button","variant":"light"},on:{"click":_vm.resetForm}},[_vm._v("Reset")])],1)],1)])])])])]}}])}),_c('vue-snotify')],1)}
var staticRenderFns = []

export { render, staticRenderFns }