import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

Vue.use(Vuex)

import moduleAuth from './auth/moduleAuth.js'
import moduleStudent from './student/moduleStudent.js'
import moduleAdmin from './admin/moduleAdmin.js'
import moduleCourse from './course/moduleCourse.js'

export default new Vuex.Store({
    getters,
    mutations,
    state,
    actions,
    modules: {
        auth: moduleAuth,
        student: moduleStudent,
        admin: moduleAdmin,
        course: moduleCourse
    },
    strict: process.env.NODE_ENV !== 'production'
})
