<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">Certificates</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'certificates' }"
              >Certificates</router-link
            >
          </li>
          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Filter Certificates</h4>
            <div class="row">
              <div class="col-md-6">
                <b-form-group label-for="course" horizontal label="Course">
                  <b-form-select
                    id="course"
                    v-model="course_id"
                    :options="courses"
                  />
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="perPage" label-for="perPage">
                  <b-form-input
                    type="number"
                    id="perPage"
                    v-model="filterPerPage"
                    placeholder="perPage"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-5">
                <date-picker
                  style="width: 100%"
                  v-model="date"
                  range
                  format="YYYY-MM-DD"
                ></date-picker>
              </div>
              <div class="col-md-3">
                <button
                  type="button"
                  class="btn btn-primary ml-0 ml-lg-3 mt-2 mt-lg-0"
                  @click="filterCertificates"
                  :disabled="disableFilter"
                >
                  Filter
                </button>
              </div>
              <div class="col-md-4">
                <button
                  type="button"
                  class="btn btn-primary ml-0 ml-lg-3 mt-2 mt-lg-0"
                  @click="fetchCertificates"
                  :disabled="!filtered"
                >
                  Clear Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Certificates</h4>
            <b-table
              :items="certificates"
              id="table-list"
              :busy="isBusy"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
            >
              <template v-slot:cell(certificate)="data">
                <span v-if="data.item.certificate !== null"
                  ><a :href="data.item.certificate" target="_blank"
                    ><img class="img-fluid" :src="data.item.certificate" /></a
                ></span>
                <span v-if="data.item.certificate === null"> N/A </span>
              </template>
              <template v-slot:cell(course)="data">
                {{ data.item.course.name }}
              </template>
              <template v-slot:cell(first_name)="data">
                {{ data.item.user.first_name }}
              </template>

              <template v-slot:cell(last_name)="data">
                {{ data.item.user.last_name }}
              </template>

              <template v-slot:cell(middle_name)="data">
                {{ data.item.user.middle_name }}
              </template>

              <template v-slot:cell(action)="data">
                <span>
                  <button
                    v-if="activeUserInfo.userRole == 'admin'"
                    @click="viewPage(data.item.user.id)"
                    class="btn btn-outline-primary px-4 py-1"
                  >
                    <i class="mdi mdi-eye-outline text-primary mr-2"></i>View
                    Student
                  </button>
                </span>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
    <div></div>
    <vue-snotify></vue-snotify>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import moment from "moment";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    DatePicker,
  },
  data: function () {
    return {
      sortBy: "id",
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      perPage: 0,
      currentPage: 1,
      isBusy: true,
      sortable: true,
      filtered: false,
      q: "",
      course_id: "",
      filterPerPage: 100,
      date: "",
      start_date: "",
      end_date: "",
      fields: [
        { key: "id", sortable: true },
        { key: "course", sortable: true },
        { key: "certificate", sortable: true },
        { key: "first_name", sortable: true },
        { key: "last_name", sortable: true },
        { key: "middle_name", sortable: true },
        { key: "created_at", sortable: true },
        { key: "action", sortable: true },
      ],
    };
  },
  computed: {
    rows() {
      return this.$store.state.student.meta.total;
    },
    paginationPageSize() {
      return this.$store.state.student.meta.per_page;
    },
    certificates() {
      return this.$store.state.student.certificates;
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    courses() {
      return this.$store.getters["course/listCoursesWithId"];
    },
    disableFilter() {
      return (
        this.course_id === "" ||
        (this.start_date === "" && this.end_date !== "") ||
        (this.end_date === "" && this.start_date !== "")
      );
    },
  },
  watch: {
    currentPage() {
      if (this.currentPage != this.$store.state.student.meta.current_page)
        this.fetchCertificates();
    },
    date() {
      if (this.date != "") {
        this.start_date = moment(this.date[0]).format("YYYY-MM-DD");
        this.end_date = moment(this.date[1]).format("YYYY-MM-DD");
      } else {
        this.start_date = "";
        this.end_date = "";
        this.fetchCertificates();
      }
    },
  },
  methods: {
    viewPage(id) {
      this.$router.push("/students/" + id + "/view");
    },
    fetchCertificates() {
      this.isBusy = true;
      const payload = {
        meta: {
          page: this.currentPage,
          perPage: this.paginationPageSize,
        },
      };

      this.$store
        .dispatch("student/fetchCertificates", payload)
        .then(() => {
          this.isBusy = false;
          this.filtered = false;
        })
        .catch(() => {
          this.isBusy = false;
        });
    },
    filterCertificates() {
      this.isBusy = true;
      const payload = {
        meta: {
          page: 1,
          perPage: this.filterPerPage,
          course_id: this.course_id,
          start_date: this.start_date,
          end_date: this.end_date,
        },
      };

      for (const [key, value] of Object.entries(payload.meta)) {
        if (value === "") delete payload.meta[key];
      }

      this.$store
        .dispatch("student/filterCertificates", payload)
        .then(() => {
          this.isBusy = false;
          this.filtered = true;
        })
        .catch(() => {
          this.isBusy = false;
        });
    },
  },
  created() {
    this.fetchCertificates();
    this.$store.dispatch("course/fetchCourses");
  },
};
</script>
