<template>
  <section class="dashboard">
    <div class="page-header">
      <h3 class="page-title">
        <span class="page-title-icon bg-gradient-primary text-white mr-2">
          <i class="mdi mdi-home"></i>
        </span>
        Dashboard
      </h3>
      <nav aria-label="breadcrumb">
        <ul class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">
            <span></span>Overview
            <i
              class="
                mdi mdi-alert-circle-outline
                icon-sm
                text-primary
                align-middle
              "
            ></i>
          </li>
        </ul>
      </nav>
    </div>
    <div class="row" v-if="userInfo.userRole !== 'patient_manager'">
      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
        <div class="card card-statistics">
          <div class="card-body">
            <div class="clearfix">
              <div class="float-left">
                <i
                  class="mdi mdi-account-supervisor-outline text-success icon-lg"
                ></i>
              </div>
              <div class="float-right">
                <p class="mb-0 text-right">Students</p>
                <div class="fluid-container">
                  <h3 class="font-weight-medium text-right mb-0">
                    {{ stats.students }}
                  </h3>
                </div>
              </div>
            </div>
            <p class="text-muted mt-3 mb-0">
              <router-link :to="{ name: 'student-list' }">
                <i
                  class="mdi mdi-bookmark-outliine mr-1"
                  aria-hidden="true"
                ></i>
                View more information
              </router-link>
            </p>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
        <div class="card card-statistics">
          <div class="card-body">
            <div class="clearfix">
              <div class="float-left">
                <i class="mdi mdi-account-supervisor text-warning icon-lg"></i>
              </div>
              <div class="float-right">
                <p class="mb-0 text-right">Pending Students</p>
                <div class="fluid-container">
                  <h3 class="font-weight-medium text-right mb-0">
                    {{ stats.pending_students }}
                  </h3>
                </div>
              </div>
            </div>
            <p class="text-muted mt-3 mb-0">
              <router-link :to="{ name: 'pending-student' }">
                <i class="mdi mdi-bookmark-outline mr-1" aria-hidden="true"></i>
                View more information
              </router-link>
            </p>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
        <div class="card card-statistics">
          <div class="card-body">
            <div class="clearfix">
              <div class="float-left">
                <i class="mdi mdi-barcode text-danger icon-lg"></i>
              </div>
              <div class="float-right">
                <p class="mb-0 text-right">Codes</p>
                <div class="fluid-container">
                  <h3 class="font-weight-medium text-right mb-0">
                    {{ stats.codes }}
                  </h3>
                </div>
              </div>
            </div>
            <p class="text-muted mt-3 mb-0">
              <router-link :to="{ name: 'code-list' }">
                <i class="mdi mdi-bookmark-outline mr-1" aria-hidden="true"></i>
                View more information
              </router-link>
            </p>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
        <div class="card card-statistics">
          <div class="card-body">
            <div class="clearfix">
              <div class="float-left">
                <i class="mdi mdi-folder-open-outline text-success icon-lg"></i>
              </div>
              <div class="float-right">
                <p class="mb-0 text-right">Courses</p>
                <div class="fluid-container">
                  <h3 class="font-weight-medium text-right mb-0">
                    {{ stats.courses }}
                  </h3>
                </div>
              </div>
            </div>
            <p class="text-muted mt-3 mb-0">
              <router-link :to="{ name: 'courses-list' }">
                <i class="mdi mdi-bookmark-outline mr-1" aria-hidden="true"></i>
                View more information
              </router-link>
            </p>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
        <div class="card card-statistics">
          <div class="card-body">
            <div class="clearfix">
              <div class="float-left">
                <i class="mdi mdi-folder-open-outline text-warning icon-lg"></i>
              </div>
              <div class="float-right">
                <p class="mb-0 text-right">Ongoing Studies</p>
                <div class="fluid-container">
                  <h3 class="font-weight-medium text-right mb-0">
                    {{ stats.ongoing }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
        <div class="card card-statistics">
          <div class="card-body">
            <div class="clearfix">
              <div class="float-left">
                <i class="mdi mdi-folder text-success icon-lg"></i>
              </div>
              <div class="float-right">
                <p class="mb-0 text-right">Completed Studies</p>
                <div class="fluid-container">
                  <h3 class="font-weight-medium text-right mb-0">
                    {{ stats.completed }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
        <div class="card card-statistics">
          <div class="card-body">
            <div class="clearfix">
              <div class="float-left">
                <i class=" mdi mdi-certificate text-success icon-lg"></i>
              </div>
              <div class="float-right">
                <p class="mb-0 text-right">Issued Certificates</p>
                <div class="fluid-container">
                  <h3 class="font-weight-medium text-right mb-0">
                    {{ stats.issued }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="userInfo.userRole === 'patient_manager'">
      <div class="col-md-12">
        <p>Nothing for you to see here.</p>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable no-debugger, no-console */

export default {
  name: "dashboard",
  components: {},
  data() {
    return {};
  },
  watch: {},
  computed: {
    userInfo() {
      return this.$store.state.AppActiveUser;
    },
    stats() {
      return this.$store.state.course.stats;
    },
  },
  methods: {},
  created() {
    if (["admin"].includes(this.userInfo.userRole)) {
      this.$store.dispatch("course/fetchStats");
    }
  },
};
</script>

<style scoped>
</style>
