import student from "../../http/requests/student/index.js"
/* eslint-disable no-debugger, no-console */

export default {
  fetchStudents({ commit }, payload) {
    return new Promise((resolve, reject) => {
      student.fetchStudents(payload.meta)
        .then((response) => {
           if(response.data.data) {
             commit('SET_STUDENTS', response.data.data)
             commit('SET_META', response.data.meta)
             commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  filterStudentsByCourses({ commit }, payload) {
    return new Promise((resolve, reject) => {
      student.filterStudentsByCourses(payload.meta)
        .then((response) => {
           if(response.data.data) {
             commit('SET_STUDENTS', response.data.data)
             commit('SET_META', response.data.meta)
             commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchPendingStudents({ commit }, payload) {
    return new Promise((resolve, reject) => {
      student.fetchPendingStudents(payload.meta)
        .then((response) => {
           if(response.data.data) {
             commit('SET_STUDENTS', response.data.data)
             commit('SET_META', response.data.meta)
             commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCertificates({ commit }, payload) {
    return new Promise((resolve, reject) => {
      student.fetchCertificates(payload.meta)
        .then((response) => {
          if (response.data.data) {
            commit('SET_CERTIFICATES', response.data.data)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), { root: true })
            resolve(response)
          } else {
            reject({ message: response.data.message })
          }

        })
        .catch((error) => { reject(error) })
    })
  },
  filterCertificates({ commit }, payload) {
    return new Promise((resolve, reject) => {
      student.filterCertificates(payload.meta)
        .then((response) => {
          if (response.data.data) {
            commit('SET_CERTIFICATES', response.data.data)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), { root: true })
            resolve(response)
          } else {
            reject({ message: response.data.message })
          }

        })
        .catch((error) => { reject(error) })
    })
  },
  fetchCodes({ commit }, payload) {
    return new Promise((resolve, reject) => {
      student.fetchCodes(payload.meta)
        .then((response) => {
           if(response.data.data) {
             commit('SET_STUDENTS', response.data.data)
             commit('SET_META', response.data.meta)
             commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchStudent({ commit }, id) {
    return new Promise((resolve, reject) => {
      student.fetchStudent(id)
        .then((response) => {
           if(response.data.data) {
             commit('SET_STUDENT', response.data.data)
             commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  approveApplication({ commit }, payload) {
    return new Promise((resolve, reject) => {
      student.approveApplication(payload)
        .then((response) => {
           if(response.data) {
             commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteStudent({ commit }, payload) {
    return new Promise((resolve, reject) => {
      student.deleteStudent(payload)
        .then((response) => {
           if(response.data) {
             commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error.response.data)
        })
    })
  },
  createStudent({ commit }, payload) {
    return new Promise((resolve, reject) => {
      student.createStudent(payload)
        .then((response) => {
          if(response.status === 201) {
            commit('SET_STUDENT', response.data.data)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
        } else {
            reject({message: "Something went wrong!"})
        }
 
        })
        .catch((error) => { reject(error.response.data) })
    })
  },
  updateStudent({ commit }, payload) {
    return new Promise((resolve, reject) => {
      student.updateStudent(payload)
        .then((response) => {
          if(response.data.user) {
            commit('SET_STUDENT', response.data.user)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
        } else {
            reject({message: "Something went wrong!"})
        }
 
        })
        .catch((error) => { reject(error.response.data) })
    })
  }
}
