import state from './moduleStudentState.js'
import mutations from './moduleStudentMutations.js'
import actions from './moduleStudentActions.js'
import getters from './moduleStudentGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
