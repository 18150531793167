<template>
  <section class="forms">
    <div class="page-header">
      <h3 class="page-title">Import Tests</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'courses-list' }">Courses</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Bulk Certificates Upload List</li>
        </ol>
      </nav>
    </div>
    <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="form">
      <form @submit.prevent="handleSubmit(onSubmit)" class="forms-sample">
        <div class="row">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Bulk Completion</h4>
                <p class="card-description text-danger">please verify file before upload; make sure you select the correct course.</p>
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider name="name" rules="required">
                      <b-form-group
                        slot-scope="{ errors }"
                        label="Name"
                        label-for="name"
                      >
                        <b-form-input
                          type="text"
                          id="name"
                          v-model="name"
                          placeholder="Name"
                        ></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Courses" rules="required">
                      <b-form-group
                        slot-scope="{ errors }"
                        label-for="individual_status"
                        horizontal
                        label="Courses"
                      >
                        <b-form-select
                          id="courses"
                          v-model="course_id"
                          :options="courses"
                        />
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="rows" rules="required">
                      <b-form-group
                        slot-scope="{ errors }"
                        label="Import File"
                        label-for="rows"
                      >
                        <b-form-file
                          v-model="file"
                          accept=".csv"
                          id="rows"
                          :state="Boolean(file)"
                          placeholder="Choose a file..."
                        ></b-form-file>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <b-form-group class="float-right">
                  <b-button
                    type="submit"
                    variant="success"
                    :disabled="invalid"
                    class="mr-2"
                    >{{ create_text }}</b-button
                  >
                  <b-button type="button" @click="resetForm" variant="light"
                    >Clear</b-button
                  >
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <vue-snotify></vue-snotify>
  </section>
</template>

<script lang="js">
/* eslint-disable no-debugger, no-console */

import { ValidationObserver } from 'vee-validate'

export default {
  name: 'bulkCompletion',
  data () {
    return {
      file: null,
      name: "",
      course_id: "",
      create_text: "Bulk Upload"
    }
  },
  components: {
    ValidationObserver
  },
  computed: {
    courses() {
      return this.$store.getters["course/listCoursesWithId"];
    },
  },
  methods: {
    onSubmit() {
      this.create_text = "Importing..."
      let form_data = new FormData()

      form_data.append("file", this.file, this.file.name)

      form_data.append('name', this.name)

      form_data.append('course_id', this.course_id)

      this.$store.dispatch('course/addImport', form_data)
      .then((res) => {
        this.resetForm()
        this.$snotify.success(res.data.message, {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      })
      .catch((error) => {
        let message = "Something went wrong!"
        if(error.message)
          message = error.response.data.message

        this.$snotify.warning(message, {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      })
      .finally(() => {
        this.create_text = "Bulk Upload"
      })
    },
    resetForm() {
      this.file = null
      this.name = ""
      this.$nextTick(() => {
        this.$refs.form.reset();
      })
    },
  },
  created() {
    this.$store.dispatch("course/fetchCourses")
  }
}
</script>
