import course from "../../http/requests/course/index.js"
/* eslint-disable no-debugger, no-console */

export default {
  generateCodes({ commit }, payload) {
    return new Promise((resolve, reject) => {
      course.generateCodes(payload)
        .then((response) => {
          if (response.status === 201) {
            commit('UPDATE_TIMESTAMP', new Date().getTime(), { root: true })
            resolve(response)
          } else {
            reject({ message: "Something went wrong!" })
          }

        })
        .catch((error) => { reject(error.response.data) })
    })
  },
  addNew({ commit }, payload) {
    return new Promise((resolve, reject) => {
      course.addNew(payload)
        .then((response) => {
          if (response.status === 201) {
            commit('UPDATE_TIMESTAMP', new Date().getTime(), { root: true })
            commit("student/SET_STUDENT", response.data.data, { root: true })
            resolve(response)
          } else {
            reject({ message: "Something went wrong!" })
          }

        })
        .catch((error) => { reject(error.response.data) })
    })
  },
  removeCourse({ commit }, id) {
    return new Promise((resolve, reject) => {
      course.removeCourse(id)
        .then((response) => {
          if (response.status === 200) {
            commit('UPDATE_TIMESTAMP', new Date().getTime(), { root: true })
            commit("student/SET_STUDENT", response.data.data, { root: true })
            resolve(response)
          } else {
            reject({ message: "Something went wrong!" })
          }

        })
        .catch((error) => { reject(error.response.data) })
    })
  },
  sendCertificate({ commit }, id) {
    return new Promise((resolve, reject) => {
      course.sendCertificate(id)
        .then((response) => {
          if (response.status === 200) {
            commit('UPDATE_TIMESTAMP', new Date().getTime(), { root: true })
            resolve(response)
          } else {
            reject({ message: "Something went wrong!" })
          }

        })
        .catch((error) => { reject(error.response.data) })
    })
  },
  generateCertificate({ commit }, id) {
    return new Promise((resolve, reject) => {
      course.generateCertificate(id)
        .then((response) => {
          if (response.status === 200) {
            commit('UPDATE_TIMESTAMP', new Date().getTime(), { root: true })
            commit("student/SET_STUDENT", response.data.data, { root: true })
            resolve(response)
          } else {
            reject({ message: "Something went wrong!" })
          }

        })
        .catch((error) => { reject(error.response.data) })
    })
  },
  fetchCourses({ commit }) {
    return new Promise((resolve, reject) => {
      course.fetchCourses()
        .then((response) => {
          if (response.data.data) {
            commit('SET_COURSES', response.data.data)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), { root: true })
            resolve(response)
          } else {
            reject({ message: response.data.message })
          }

        })
        .catch((error) => { reject(error) })
    })
  },
  fetchImports({ commit }, payload) {
    return new Promise((resolve, reject) => {
      course.fetchImports(payload.meta)
        .then((response) => {
           if(response.data.data) {
             commit('SET_IMPORTS', response.data.data)
             commit('SET_META', response.data.meta)
             commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCoordinators({ commit }) {
    return new Promise((resolve, reject) => {
      course.fetchCoordinators()
        .then((response) => {
          if (response.data.data) {
            commit('SET_COORDINATORS', response.data.data)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), { root: true })
            resolve(response)
          } else {
            reject({ message: response.data.message })
          }

        })
        .catch((error) => { reject(error) })
    })
  },
  fetchCoordinator({ commit }, id) {
    return new Promise((resolve, reject) => {
      course.fetchCoordinator(id)
        .then((response) => {
          if (response.data.data) {
            commit('UPDATE_TIMESTAMP', new Date().getTime(), { root: true })
            resolve(response)
          } else {
            reject({ message: response.data.message })
          }

        })
        .catch((error) => { reject(error) })
    })
  },
  addCoordinator({ commit }, payload) {
    return new Promise((resolve, reject) => {
      course.addCoordinator(payload)
        .then((response) => {
          if (response.status === 201) {
            commit('UPDATE_TIMESTAMP', new Date().getTime(), { root: true })
            commit("SET_COORDINATOR", response.data.data)
            resolve(response)
          } else {
            reject({ message: "Something went wrong!" })
          }

        })
        .catch((error) => { reject(error.response.data) })
    })
  },
  addImport({ commit }, payload) {
    return new Promise((resolve, reject) => {
      course.addImport(payload)
        .then((response) => {
          if (response.status === 201) {
            commit('UPDATE_TIMESTAMP', new Date().getTime(), { root: true })
            resolve(response)
          } else {
            reject({ message: "Something went wrong!" })
          }

        })
        .catch((error) => { reject(error.response.data) })
    })
  },
  updateCoordinator({ commit }, payload) {
    return new Promise((resolve, reject) => {
      course.updateCoordinator(payload)
        .then((response) => {
          if (response.status === 200) {
            commit('UPDATE_TIMESTAMP', new Date().getTime(), { root: true })
            commit("SET_COORDINATOR", response.data.data)
            resolve(response)
          } else {
            reject({ message: "Something went wrong!" })
          }

        })
        .catch((error) => { reject(error.response.data) })
    })
  },
  addCoordinatorTo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      course.addCoordinatorTo(payload)
        .then((response) => {
          if (response.status === 200) {
            commit('UPDATE_TIMESTAMP', new Date().getTime(), { root: true })
            commit("SET_COURSE", response.data.data)
            resolve(response)
          } else {
            reject({ message: "Something went wrong!" })
          }

        })
        .catch((error) => { reject(error.response.data) })
    })
  },
  fetchStats({ commit }) {
    return new Promise((resolve, reject) => {
      course.fetchStats()
        .then((response) => {
          if (response.data) {
            commit('SET_STATS', response.data)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), { root: true })
            resolve(response)
          } else {
            reject({ message: response.data.message })
          }

        })
        .catch((error) => { reject(error) })
    })
  },
  fetchRegistrar({ commit }) {
    return new Promise((resolve, reject) => {
      course.fetchRegistrar()
        .then((response) => {
          if (response.data) {
            commit('UPDATE_TIMESTAMP', new Date().getTime(), { root: true })
            resolve(response)
          } else {
            reject({ message: response.data.message })
          }

        })
        .catch((error) => { reject(error) })
    })
  },
  updateRegistrar({ commit }, payload) {
    return new Promise((resolve, reject) => {
      course.updateRegistrar(payload)
        .then((response) => {
          if (response.data) {
            commit('UPDATE_TIMESTAMP', new Date().getTime(), { root: true })
            resolve(response)
          } else {
            reject({ message: response.data.message })
          }

        })
        .catch((error) => { reject(error) })
    })
  },
}
