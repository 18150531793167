import axios from "../../axios/index.js"

export default {
  createAdmin(payload) {
    return axios.post("manage", payload)
  },
  fetchAdmins(meta) {
    return axios.get("manage", {
      params: meta
    })
  },
  fetchStats() {
    return axios.get("manage/stats")
  }
}
