<template>
  <b-modal id="add_coordinator" title="Add Coordinator">
    <ValidationObserver v-slot="{ invalid }">
      <form @submit.prevent="onSubmit" class="pt-3">
        <ValidationProvider name="Coordinator" rules="required">
          <b-form-group
            slot-scope="{ errors }"
            label-for="coordinator"
            horizontal
            label="Courses"
          >
            <b-form-select
              id="courses"
              v-model="coordinator"
              :options="coordinators"
            />
            <p>{{ errors[0] }}</p>
          </b-form-group>
        </ValidationProvider>
        <div class="mt-3">
          <b-button
            type="submit"
            :disabled="invalid"
            variant="primary"
            class="
              btn btn-block btn-gradient-primary btn-lg
              font-weight-medium
              auth-form-btn
            "
            >{{ processing ? "Processing..." : "Add Coordinator" }}</b-button
          >
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import { ValidationObserver } from "vee-validate";

export default {
  props: {
    course_id: {
        type: Number,
        default: 0
    }
  },
  name: "add-coordinator",
  components: {
    ValidationObserver,
  },
  data() {
    return {
      coordinator: "",
      processing: false
    };
  },
  computed: {
    coordinators() {
      return this.$store.getters["course/listCoordinatorsWithId"];
    },
  },
  methods: {
    fetchCoordinators() {
      this.$store.dispatch("course/fetchCoordinators");
    },
    onSubmit() {
        this.processing = true
        this.$store.dispatch("course/addCoordinatorTo", {course_id: this.course_id, coordinator_id: this.coordinator})
        .then(() => {
            this.$bvModal.hide("add_coordinator")
            this.$emit("added")
        })
        .catch(() => {

        })
        .finally(() => {
            this.processing = false
        })
    }
  },
  created() {
    this.fetchCoordinators();
  },
};
</script>
