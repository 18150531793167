<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">
        Coordinators
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Coordinators</a></li>
          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{ list_title }}</h4>
            <b-table :items="coordinators" id="table-list" responsive :busy="isBusy" :per-page="perPage" :current-page="currentPage" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template v-slot:cell(signature)="data">
                <span><img :src="data.item.signature" width="100px" /></span>
              </template>
                            <template v-slot:cell(action)="data">
                <span>
                  <button
                    @click="editCoordinator(data.item.id)"
                    class="btn btn-outline-primary px-4 py-1"
                  >
                    <i class="mdi mdi-account-edit text-primary mr-2"></i>Edit
                  </button>
                </span>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      </div>
    <div>
    </div>
    <vue-snotify></vue-snotify>
  </section>
</template>
<script>
/* eslint-disable no-debugger, no-console */

import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  data: function() {
    return {
      q: "",
      sortBy: 'id',
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      list_title: "Coordinators",
      currentPage: 1,
      perPage: 0,
      isBusy: true,
      fields: [
        { key: 'id', label: 'id', sortable: true },
        { key: 'first_name', sortable: true },
        { key: 'last_name', sortable: true},
        { key: 'signature', sortable: true },
        { key: 'created_at', sortable: true },
        { key: 'action', sortable: false}
      ],
    }
  },
  computed: {
    coordinators() {
      return this.$store.state.course.coordinators
    }
  },
  methods: {
    fetchCoordinators() {
      this.isBusy = true

      this.$store.dispatch("course/fetchCoordinators")
      .then(() => {
        this.isBusy = false
      })
      .catch(() => {
        this.isBusy = false
      })
    },
    editCoordinator(id) {
        this.$router.push("/courses/coordinators/"+id)
    }
  },
  created() {
    this.fetchCoordinators()
 },
}
</script>
