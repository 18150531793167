<template>
  <section class="forms">
    <div class="page-header">
      <h3 class="page-title">Update Coordinator</h3>
    </div>
    <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="form">
      <form @submit.prevent="handleSubmit(onSubmit)" class="forms-sample">
        <div class="row">
          <div class="col-md-6 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Coordinator Data</h4>
                <p class="card-description">Please input registrar data here.</p>
                <ValidationProvider name="first_name" rules="required">
                  <b-form-group
                    slot-scope="{ errors }"
                    label="First Name"
                    label-for="first_name"
                  >
                    <b-form-input
                      type="text"
                      id="name"
                      v-model="first_name"
                      placeholder="First Name"
                    ></b-form-input>
                    <p>{{ errors[0] }}</p>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider name="last_name" rules="required">
                  <b-form-group
                    slot-scope="{ errors }"
                    label="Last Name"
                    label-for="last_name"
                  >
                    <b-form-input
                      type="text"
                      id="last_name"
                      v-model="last_name"
                      placeholder="Last Name"
                    ></b-form-input>
                    <p>{{ errors[0] }}</p>
                  </b-form-group>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <div class="col-md-6 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <ValidationProvider name="signature">
                  <b-form-group
                    label="Signature"
                    label-for="signature"
                  >
                    <b-form-file
                      @change="getMobileURL"
                      v-model="signature_file"
                      accept=".png,.jpg,.jpeg,.gif"
                      id="mobile_banner"
                      placeholder="Choose Signature"
                    ></b-form-file>
                  </b-form-group>
                </ValidationProvider>
                <p>
                  <ul class="text-danger">
                    <li>Signature should be in transparent background.</li>
                    <li>Signature's height must not be less than 400px, and cannot be greater than 450px with width not more than 600px.</li>
                  </ul>
                </p>
                <div v-if="signature_url != ''" class="mt-2">
                  <img :src="signature_url" width="150px" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <b-form-group class="float-right">
                  <b-button
                    type="submit"
                    variant="success"
                    :disabled="invalid"
                    class="mr-2"
                    >{{ create_text }}</b-button
                  >
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <vue-snotify></vue-snotify>
  </section>
</template>

<script lang="js">
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'updateCoordinator',
  data () {
    return {
      first_name: "",
      last_name: "",
      signature_file: null,
      signature_url: "",
      signature_photo: null,
      create_text: "Update Registrar"

    }
  },
  components: {
    ValidationObserver
  },
  computed: {
  },
  methods: {
    onSubmit() {
      this.create_text = "Updating..."
      let form_data = new FormData()

      form_data.append("first_name", this.first_name)
      form_data.append("last_name", this.last_name)
      if (this.signature_photo !== null)
          form_data.append("signature", this.signature_photo, this.signature_photo.name)

      this.$store.dispatch('course/updateCoordinator', {id: this.$route.params.id, form_data: form_data})
      .then((res) => {
        this.$snotify.success(res.data.message, {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      })
      .catch((res) => {
        if (res.status_code === 422) {
          Object.keys(res.errors).forEach(data => {
            res.errors[data].forEach(err => {
              this.$snotify.warning(err, {
                timeout: 2000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true
              })
            })
          })
        }
        else {
            this.$snotify.warning(res.message, {
                timeout: 2000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true
            })
        }
      })
      .finally(() => {
        this.create_text = "Update Coordinator"
      })
    },
    getMobileURL(input) {
      if (input.target.files && input.target.files[0]) {
       var reader = new FileReader()
       reader.onload = e => {
         this.signature_url = e.target.result
         this.signature_photo = input.target.files[0]
       }
       reader.readAsDataURL(input.target.files[0])
     }
    },
    fetchCoordinator() {
      this.$store.dispatch("course/fetchCoordinator", this.$route.params.id)
      .then((res) => {
        this.first_name = res.data.data.first_name
        this.last_name = res.data.data.last_name
        this.signature_url = res.data.data.signature
      })
    },
  },
  created() {
    this.fetchCoordinator()
  }
}
</script>
