import admin from "../../http/requests/admin/index.js"

export default {
  createAdmin({ commit }, payload) {
   return new Promise((resolve, reject) => {
     admin.createAdmin(payload)
       .then((response) => {
         if(response.status === 201) {
           commit('SET_ADMIN', response.data.data)
           commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
           resolve(response)
       } else {
           reject({message: "Something went wrong!"})
       }

       })
       .catch((error) => { reject(error.response.data) })
   })
 },
 fetchAdmins({ commit }, payload) {
   return new Promise((resolve, reject) => {
     admin.fetchAdmins(payload.meta)
       .then((response) => {
          if(response.data.data[0]) {
            commit('SET_ADMINS', response.data.data)
            commit('SET_META', response.data.meta)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
        } else {
           reject({message: response.data.message})
        }
       })
       .catch((error) => {
         reject(error)
       })
   })
 },
 fetchStats({ commit }) {
  return new Promise((resolve, reject) => {
    admin.fetchStats()
      .then((response) => {
         if(response.data.data) {
           commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
           resolve(response)
       } else {
          reject({message: response.data.message})
       }
      })
      .catch((error) => {
        reject(error)
      })
  })
},
  updatePaginate({ commit }, size) {
    commit('UPDATE_PAGINATE', size)
  }
}
