import axios from "../../../axios/index.js"

let baseURL = 'https://pnuemathealth.com/api/v1'

if (process.env.NODE_ENV === "development") {
  baseURL = 'http://localhost:8000/api/v1'
} 

export default {
  login(email, client_type) {
    return axios.post(`${baseURL}/auth/request/otp`, {email: email, client_type: client_type})
  },
  verify(token) {
    return axios.post(`${baseURL}/auth/admin/signin`, {otp: token, email: localStorage.getItem('email'), otp_secret: localStorage.getItem('otp_secret')})
  },
  logout() {
    return axios.post(`${baseURL}/auth/logout`)
  },
  fetchDetails(token) {
    return axios.get(`${baseURL}auth/details`, {
      params: {
        token: token
      }
    })
  }
}
