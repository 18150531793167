export default {
  students: [],
  tests: [],
  certificates: [],
  stats: {
    all: 0,
    active: 0,
    inactive: 0,
  },
  meta: {
    current_page: 1,
    next_page: 1,
    per_page: 15,
    from: 0,
    to: 0,
    total: 0,
    last_page: 1
  },
}
