export default {
  courses: [],
  coordinators: [],
  imports: [],
  stats: {
    students: 0,
    pending_students: 0,
    codes: 0,
    courses: 0,
    ongoing: 0,
    completed: 0,
    issued: 0
  },
  meta: {
    current_page: 1,
    next_page: 1,
    per_page: 10,
    from: 0,
    to: 0,
    total: 0,
    last_page: 1
  },
}
