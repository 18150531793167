<template>
  <section class="profile">
    <div class="page-header">
      <h3 class="page-title">{{ user.full_name }}</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'student-list' }">Students</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ user.full_name }}
          </li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <b-tabs class="tab-solid tab-solid-primary">
              <b-tab>
                <template slot="title">
                  <i class="mdi mdi-home-outline"></i> Summary
                </template>
                <div class="row">
                  <div class="col-12">
                    <img
                      class="img-lg rounded-circle mb-3"
                      src="@/assets/images/faces/face1.jpg"
                      alt="User Avatar"
                    />
                    <h3 class="card-title">{{ user.full_name }}</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <p>
                      <span class="d-block"
                        ><strong>Phone:</strong> {{ user.phone }}</span
                      >
                      <span class="d-block">
                        <strong>Email:</strong>
                        {{ user.email }}</span
                      >
                      <span class="d-block">
                        <strong>Date Created:</strong>
                        {{
                          formatTime(user.date_created, "DD/MM/YYYY hh:mm:ss")
                        }}</span
                      >
                      <span class="d-block">
                        <strong>Status:</strong>
                        {{ user.status_readable }}</span
                      >
                    </p>
                  </div>
                  <div class="col-md-6">
                    <p>
                      <span class="d-block"
                        ><strong>Student Id:</strong>
                        {{ user.student_id }}</span
                      >
                      <span class="d-block">
                        <strong>Date of Birth</strong>
                        {{ user.date_of_birth }}</span
                      >
                      <span class="d-block">
                        <strong>Address</strong>
                        {{ user.address }}</span
                      >
                      <span class="d-block">
                        <strong>Last Updated:</strong
                        >{{
                          formatTime(user.date_created, "DD/MM/YYYY hh:mm:ss")
                        }}</span
                      >
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <b-button @click="editUser" type="button" variant="primary"
                      ><i class="mdi mdi-tooltip-edit"></i> Edit</b-button
                    >
                  </div>
                </div>
              </b-tab>

              <courses @added="added" :courses="user.courses"></courses>
              <next-of-kin :kin="user.next_of_kin"></next-of-kin>
            </b-tabs>
            <vue-snotify></vue-snotify>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable no-debugger, no-console */
import helper from "../../util/helper.js";
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import NextOfKin from "./components/NextOfKin.vue";
import Courses from "./components/Courses.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});
export default {
  name: "profile",
  data() {
    return {
      user: {
        next_of_kin: {},
        courses: [],
      },
      sortBy: "name",
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      date_range: "",
      start_date: "",
      end_date: "",
      city_id: "",
      plot_no: "",
      plan_no: "",
      list_title: "Plots",
      currentPagePlot: 1,
      perPage: 0,
      isBusyPlot: true,
    };
  },
  components: {
    NextOfKin,
    Courses,
  },
  methods: {
    formatTime(time, format) {
      return helper.formatTime(time, format);
    },
    editUser() {
      this.$router.push(`/students/${this.$route.params.id}`);
    },
    added(data) {
      this.user = data;
    },
  },
  created() {
    this.$store
      .dispatch("student/fetchStudent", this.$route.params.id)
      .then((res) => {
        this.user = res.data.data;
      })
      .catch(() => {
        this.$router.push("/students/list");
      });
  },
};
</script>
