<template>
  <section class="forms">
    <div class="page-header">
      <h3 class="page-title">Update Registrar</h3>
    </div>
    <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="form">
      <form @submit.prevent="handleSubmit(onSubmit)" class="forms-sample">
        <div class="row">
          <div class="col-md-6 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Registrar Data</h4>
                <p class="card-description">Please input registrar data here.</p>
                <ValidationProvider name="name" rules="required">
                  <b-form-group
                    slot-scope="{ errors }"
                    label="Name"
                    label-for="name"
                  >
                    <b-form-input
                      type="text"
                      id="name"
                      v-model="name"
                      placeholder="Name"
                    ></b-form-input>
                    <p>{{ errors[0] }}</p>
                  </b-form-group>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <div class="col-md-6 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <ValidationProvider name="signature" rules="required">
                  <b-form-group
                    slot-scope="{ errors }"
                    label="Signature"
                    label-for="signature"
                  >
                    <b-form-file
                      @change="getMobileURL"
                      v-model="signature_file"
                      accept=".png,.jpg,.jpeg,.gif"
                      id="mobile_banner"
                      :state="Boolean(signature_file)"
                      placeholder="Choose Signature"
                    ></b-form-file>
                    <p>{{ errors[0] }}</p>
                  </b-form-group>
                </ValidationProvider>
                <p>
                  <ul class="text-danger">
                    <li>Signature should be in transparent background.</li>
                    <li>Signature's height must not be less than 400px, and cannot be greater than 450px with width not more than 600px.</li>
                  </ul>
                </p>
                <div v-if="signature_url != ''" class="mt-2">
                  <img :src="signature_url" width="150px" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <b-form-group class="float-right">
                  <b-button
                    type="submit"
                    variant="success"
                    :disabled="invalid"
                    class="mr-2"
                    >{{ create_text }}</b-button
                  >
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <vue-snotify></vue-snotify>
  </section>
</template>

<script lang="js">
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'updateRegistrar',
  data () {
    return {
      name: "",
      signature_file: null,
      signature_url: "",
      signature_photo: null,
      create_text: "Update Registrar"

    }
  },
  components: {
    ValidationObserver
  },
  computed: {
  },
  methods: {
    onSubmit() {
      this.create_text = "Updating..."
      let form_data = new FormData()

      form_data.append("name", this.name)
      form_data.append("signature", this.signature_photo, this.signature_photo.name)

      this.$store.dispatch('course/updateRegistrar', form_data)
      .then((res) => {
        this.$snotify.success(res.data.message, {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      })
      .catch((res) => {
        if (res.status_code === 422) {
          Object.keys(res.errors).forEach(data => {
            res.errors[data].forEach(err => {
              this.$snotify.warning(err, {
                timeout: 2000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true
              })
            })
          })
        }
        else {
            this.$snotify.warning(res.message, {
                timeout: 2000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true
            })
        }
      })
      .finally(() => {
        this.create_text = "Update Registrar"
      })
    },
    getMobileURL(input) {
      if (input.target.files && input.target.files[0]) {
       var reader = new FileReader()
       reader.onload = e => {
         this.signature_url = e.target.result
         this.signature_photo = input.target.files[0]
       }
       reader.readAsDataURL(input.target.files[0])
     }
    },
    fetchRegistrar() {
      this.$store.dispatch("course/fetchRegistrar")
      .then((res) => {
        if (res.data.data != null) {
            this.name = res.data.data.name
            this.signature_url = res.data.data.signature
        }
      })
    },
  },
  created() {
    this.fetchRegistrar()
  }
}
</script>
