export default {
    userSuggestions(state) {
      let users = state.customers.map(customer => {
        return {
          value: customer.id,
          text: `${customer.first_name} ${customer.last_name}`
        }
      })
      return [
          {
              data: users
          }
      ]
    }
 }
 