var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"forms"},[_c('div',{staticClass:"page-header"},[_c('h3',{staticClass:"page-title"},[_vm._v("Import Tests")]),_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'courses-list' }}},[_vm._v("Courses")])],1),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v("Bulk Certificates Upload List")])])])]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"forms-sample",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 grid-margin stretch-card"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title"},[_vm._v("Bulk Completion")]),_c('p',{staticClass:"card-description text-danger"},[_vm._v("please verify file before upload; make sure you select the correct course.")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('b-form-input',{attrs:{"type":"text","id":"name","placeholder":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('p',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Courses","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label-for":"individual_status","horizontal":"","label":"Courses"}},[_c('b-form-select',{attrs:{"id":"courses","options":_vm.courses},model:{value:(_vm.course_id),callback:function ($$v) {_vm.course_id=$$v},expression:"course_id"}}),_c('p',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"rows","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Import File","label-for":"rows"}},[_c('b-form-file',{attrs:{"accept":".csv","id":"rows","state":Boolean(_vm.file),"placeholder":"Choose a file..."},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('p',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1)])])])]),_c('div',{staticClass:"col-12 grid-margin stretch-card"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-form-group',{staticClass:"float-right"},[_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"success","disabled":invalid}},[_vm._v(_vm._s(_vm.create_text))]),_c('b-button',{attrs:{"type":"button","variant":"light"},on:{"click":_vm.resetForm}},[_vm._v("Clear")])],1)],1)])])])])]}}])}),_c('vue-snotify')],1)}
var staticRenderFns = []

export { render, staticRenderFns }