<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">Pending Students</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'student-list' }">Students</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Pending</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <form action="#">
          <div class="form-group d-lg-flex">
            <input
              type="text"
              class="form-control"
              placeholder="Search Here"
              v-model="q"
            />
            <button
              type="button"
              class="btn btn-primary ml-0 ml-lg-3 mt-2 mt-lg-0"
              @click="fetchStudents"
              :disabled="q == ''"
            >
              Search
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="row" v-if="activeUserInfo.userRole === 'admin'">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6">
                <b-form-group label-for="actions" horizontal label="Bulk Actions">
                  <b-form-select id="actions" v-model="action" :options="bulkActions" />
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Students</h4>
            <b-table
              :items="students"
              id="table-list"
              :busy="isBusy"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
            >
              <template v-slot:cell(select)="data">
                <b-form-checkbox
                  name="selected-items"
                  v-model="selectedItems"
                  :value="data.item"
                >
                </b-form-checkbox>
              </template>

              <template v-slot:cell(courses)="data">
                {{
                  data.item.courses
                    .map((c) => {
                      return c.name;
                    })
                    .join(", ")
                }}
              </template>

              <template v-slot:cell(action)="data">
                <span>
                  <button
                    @click="editStudent(data.item.id)"
                    class="btn btn-outline-primary px-4 py-1"
                  >
                    <i class="mdi mdi-account-edit text-primary mr-2"></i>
                  </button>
                  <button
                    @click="approveApplication({ id: [data.item.id] })"
                    class="btn btn-outline-success px-4 py-1"
                  >
                    <i class="mdi mdi-check text-success"></i>
                  </button>
                  <button
                    @click="deleteStudent({ id: [data.item.id] })"
                    class="btn btn-outline-danger px-4 py-1"
                  >
                    <i class="mdi mdi-delete text-danger"></i>
                  </button>
                </span>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
    <div></div>
    <vue-snotify></vue-snotify>
  </section>
</template>
<script>
/* eslint-disable no-debugger, no-console */
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  data: function () {
    return {
      sortBy: "id",
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      perPage: 0,
      currentPage: 1,
      isBusy: true,
      sortable: true,
      q: "",
      action: "",
      bulkActions: [
        {
          value: "",
          text: "Please select an action",
        },
        {
          value: "approve",
          text: "Approve Application",
        },
        {
          value: "delete",
          text: "Delete Application",
        },
      ],
      selectedItems: [],
      fields: [
        { key: "select", sortable: false },
        { key: "student_id", sortable: true },
        { key: "first_name", sortable: true },
        { key: "last_name", sortable: true },
        { key: "middle_name", sortable: true },
        { key: "gender", sortable: true },
        { key: "date_of_birth", sortable: true },
        { key: "phone", sortable: true },
        { key: "email", sortable: true },
        { key: "address", sortable: true },
        { key: "courses", sortable: true },
        { key: "created_at", sortable: true },
        { key: "action", sortable: true },
      ],
    };
  },
  computed: {
    rows() {
      return this.$store.state.student.meta.total;
    },
    paginationPageSize() {
      return this.$store.state.student.meta.per_page;
    },
    students() {
      return this.$store.state.student.students;
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  watch: {
    currentPage() {
      if (this.currentPage != this.$store.state.student.meta.current_page)
        this.fetchStudents();
    },
    action(newVal) {
      if (newVal == "") return;

      if (!this.selectedItems.length) {
        this.$snotify.warning("Please select an item", {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
        return;
      }

      let items = this.selectedItems.map((item) => {
        return item.id;
      });

      if (newVal === "approve") {
        this.approveApplication({ id: items });
      } else {
        this.deleteStudent({ id: items });
      }
    },
  },
  methods: {
    editStudent(id) {
      this.$router.push("/students/" + id);
    },
    fetchStudents() {
      this.isBusy = true;
      const payload = {
        meta: {
          page: this.currentPage,
          perPage: this.paginationPageSize,
          q: this.q,
        },
      };

      for (const [key, value] of Object.entries(payload.meta)) {
        if (value === "") delete payload.meta[key];
      }

      this.$store
        .dispatch("student/fetchPendingStudents", payload)
        .then(() => {
          this.isBusy = false;
        })
        .catch(() => {
          this.isBusy = false;
        });
    },
    approveApplication(payload) {
      this.$store
        .dispatch("student/approveApplication", payload)
        .then(() => {
          this.fetchStudents();
        })
        .catch((err) => {
          this.$snotify.warning(err.response.data.message, {
            timeout: 2000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
          });
        });
    },
    deleteStudent(payload) {
      this.$store
        .dispatch("student/deleteStudent", payload)
        .then(() => {
          this.fetchStudents();
        })
        .catch((err) => {
          if (err?.status_code === 422) {
            this.$helper.throwValidation(err.errors, this.$snotify);
          } else {
            this.$snotify.warning("Something went wrong", {
              timeout: 2000,
              showProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        });
    },
  },
  created() {
    this.fetchStudents();
  },
};
</script>
